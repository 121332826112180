import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useDropzone} from 'react-dropzone';
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid2";
import FormControl from "@mui/material/FormControl";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import InputLabel from "@mui/material/InputLabel";
import { v4 as uuidv4 } from 'uuid';
import {getTokenHeader, appConfig} from "../../../utils/settings";
import {AuthContext} from "../../../context/authContext";

const PREFIX = 'TransactionsUpload';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   rightIcon: `${PREFIX}-rightIcon`,
}
const Root = styled('form')(({ theme }) => ({
   [`&.${classes.root}`]: {
      flexGrow: 1,
   },
   [`& .${classes.paper}`]: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%'
   },
   [`& .${classes.rightIcon}`]: {
      marginLeft: theme.spacing(1),
   },
}))

const DropzoneArea = styled(Box)(({ theme }) => ({
   width: 'auto',
   height: '100px',
   border: '2px dotted',
   borderColor: theme.palette.primary.main, // Use primary color
   borderRadius: '5px',
   padding: '20px', // Add padding inside the box
   textAlign: 'center', // Center the text
   cursor: 'pointer',
   display: 'flex', // Use flexbox for centering
   alignItems: 'center', // Vertically center content
   justifyContent: 'center', // Horizontally center content
}));

export default function TransactionsUpload(props) {
   const open = props.open;
   const {setPasswordExpired} = useContext(AuthContext);
   const handleClose = props.handleClose;
   const accountCategories = props.categories;
   const [transactions, setTransactions] = useState([]);
   const inputRef = useRef(null);
   const [bank, setBank] = useState("wells_fargo");
   const otherCategory = "8b7df87a-4ea3-4511-b84e-6b7005cfb773";

   const {getRootProps, getInputProps, isDragActive} = useDropzone({
      accept: '.csv',
      onDrop: (acceptedFiles) => {
         handleCSVUpload(acceptedFiles[0]);
      }
   });

   const handleBankChange = () => {
      setBank("wells_fargo");
   }

   const handleImport = () => {
      let reqHeaders = getTokenHeader();
      reqHeaders = {...reqHeaders, "Content-Type": "application/json"};

      console.log(transactions);

      const chunkedTransactions = [];
      for (let i = 0; i < transactions.length; i += 100) {
         chunkedTransactions.push(transactions.slice(i, i + 100));
      }

      // Post to server in chunks of 100 transactions
      chunkedTransactions.forEach((chunk, index) => {
         fetch(appConfig.host + '/api/v1/transactions/import', {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(chunkedTransactions[index]),
         }).then(function (response) {
            // Exit and require login
            if(response.status === 401) {
               setPasswordExpired(true);
            }
            handleClose();
         })
            .catch(function (error) {
               console.error('Request failure: ', error);
            });
      });
   }

   const handleCSVUpload = async (file) => {
      if (!file) return;

      try {
         const reader = new FileReader();
         reader.onload = (e) => {
            const csvData = e.target.result;
            const parsedTransactions = parseCSVData(csvData);
            setTransactions(parsedTransactions);
         };
         reader.readAsText(file);
      } catch (error) {
         console.error('Error uploading or parsing file:', error);
      } finally {
         if (inputRef.current) {
            inputRef.current.value = ''; // Clear the input value to allow re-uploading the same file
         }
      }
   };

   const parseCSVData = (csvData) => {
      const lines = csvData.split('\n');
      let transactions = [];

      for (const line of lines) {
         if (line.trim() !== '') { // Skip empty lines
            const values = line.split('","'); // Split by the quote comma quote delimiter

            if (bank === 'wells_fargo') {
               const transaction = parseWellsFargoData(values)
               // Don't save credits
               // todo: add logic to figure out returns.
               if(transaction.amount > 0) {
                  transactions.push(transaction);
               }
            } else {
               // toast unsupported csv file
            }
         }
      }
      // todo: group related transactions
      // setSortedTransactions(getUniqueDescriptions());
      return transactions;
   };

   function parseWellsFargoData(data) {
      const transactionDateString = data[0].replace('"', ''); // Remove quotes
      const epochMs = new Date(transactionDateString).getTime();
      const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

      // Convert to epochSeconds when sending to server
      const transactionInstant = new Date(epochMs - tzOffset).getTime() / 1000;
      const amount = parseFloat(data[1].replace('"', '')); // Remove quotes and parse as float
      const description = data[4]?.replace('"', '') || "Other"; // Remove quotes or set "Other" if no description
      // transaction
      return {
         id: uuidv4(),
         transactionDate: transactionInstant,
         amount: amount * -1,
         category: otherCategory,
         description: description,
      };
   }


   const handleCategoryChange = (transactionId, newCategoryId) => {
      setTransactions((prevTransactions) => {
         return prevTransactions.map((transaction) => {
            if (transaction.id === transactionId) {
               return { ...transaction, category: newCategoryId }; // Update category ID
            }
            console.log(transaction);
            return transaction; // Leave other transactions unchanged
         });
      });
   };

   function toLocalDate(epochSeconds) {
      const date = new Date(epochSeconds * 1000);
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString('en-US', options);
   }

   return (
      <Root>
         <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
            <DialogTitle>Upload Exported Transactions</DialogTitle>
            <DialogContent>
               <Grid container sx={{paddingTop: "5px"}} rowSpacing={2} columnSpacing={2} alignItems={"center"}>
                  <Grid item size={12}>
                     <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={bank}
                        onChange={handleBankChange}
                        fullWidth={true}
                     >
                        <MenuItem value={"wells_fargo"}>Wells Fargo</MenuItem>
                     </Select>
                  </Grid>
                  <Grid item size={12}>
                     <DropzoneArea {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                           isDragActive ?
                              <Typography color="primary">Drop the files here ...</Typography> :
                              <Typography color="primary">Upload CSV File</Typography>
                        }
                     </DropzoneArea>
                  </Grid>

                  <Grid item size={12}>
                     <FormControl fullWidth variant="outlined">
                        <TableContainer component={Paper}>
                           <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead>
                                 <TableRow>
                                    <TableCell>Transaction Date</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                    <TableCell align="right">Description</TableCell>
                                    <TableCell align="right">Category</TableCell>
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {transactions.map((transaction) => (
                                    <TableRow
                                       key={transaction.id}
                                       sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                       <TableCell component="th" scope="row">{toLocalDate(transaction.transactionDate)}</TableCell>
                                       <TableCell align="right">{transaction.amount}</TableCell>
                                       <TableCell align="right">{transaction.description}</TableCell>
                                       <TableCell align="right">
                                          <FormControl fullWidth>
                                             <InputLabel id={`category-label-${transaction.id}`}>
                                                Category
                                             </InputLabel>
                                             <Select
                                                labelId={`category-label-${transaction.id}`}
                                                id={`category-select-${transaction.id}`}
                                                value={transaction.category} // Value from transaction data
                                                label="Category"
                                                onChange={(event) =>
                                                   handleCategoryChange(transaction.id, event.target.value)
                                                }
                                             >
                                                {accountCategories.map((category) => (
                                                   <MenuItem key={category.id} value={category.id}>
                                                      {category.category}
                                                   </MenuItem>
                                                ))}
                                             </Select>
                                          </FormControl>
                                       </TableCell>
                                    </TableRow>
                                 ))}
                              </TableBody>
                           </Table>
                        </TableContainer>
                     </FormControl>
                  </Grid>


               </Grid>
            </DialogContent>
            <DialogActions>
               <Button onClick={props.closeHandler}>Cancel</Button>
               <Button color='secondary' onClick={handleImport}>Import</Button>
            </DialogActions>
         </Dialog>
      </Root>
   );
}