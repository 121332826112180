
export function validateEmail(email) {
   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return regex.test(email);
}

export function validatePasswordComplexity(passwordInput) {
   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
   passwordRegex.test(passwordInput);
   return passwordRegex.test(passwordInput);
}

export function validatePasswordsMatch(currentPasswordState, pwMatchInput) {
   return currentPasswordState === pwMatchInput;
}