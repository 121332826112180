import React, {useCallback, useContext, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import {styled} from '@mui/material/styles';
import {getTokenHeader, appConfig} from "../../utils/settings";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {v4 as uuidv4} from 'uuid';
import {UserContext} from "../../context/userContext";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import {AuthContext} from "../../context/authContext";


const PREFIX = 'PriorityListInput';
const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  rightIcon: `${PREFIX}-rightIcon`,
  titleContainer: `${PREFIX}-titleContainer`,
  total: `${PREFIX}-total`,
}
const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%'
  },
  [`& .${classes.rightIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

export default function PriorityListInput(props) {
  const { open } = props;
  const {setPasswordExpired} = useContext(AuthContext);
  const {user} = useContext(UserContext);
  const [title, setTitle] = useState(props.initialTitle);
  const [items, setItems] = useState([]);

  // For new items
  const [itemDescription, setItemDescription] = useState('');
  const [itemAmount, setItemAmount] = useState(0);
  const handleClose = props.closeHandler;

  useEffect(() => {
    if(props.initialItems) {
      const formattedInitialItems = provideIdsForItems(props.initialItems);
      setItems(formattedInitialItems);
    }
    if(props.initialTitle) {
      setTitle(props.initialTitle);
    }
  }, [props.initialItems, props.initialTitle]);

  // Change handlers
  const handleTitleChange = e => setTitle(e.target.value);
  const handleAddItemDescriptionChange = (e,v)=>  setItemDescription(e.target.value);
  const handleAddItemAmountChange = (e,v) => {
    let parsedValue = 0;

    if(!isNaN(parseFloat(e.target.value))) {
      parsedValue = parseFloat(e.target.value);
    }
    setItemAmount(parsedValue);
  }
  const handleAddItem = (e,v) => {
    if(itemDescription !== "") {
      const newItem = {"id": uuidv4(), "rank": items.length, "description": itemDescription, "price": itemAmount};
      setItems([...items, newItem]);
      // clear fields
      setItemDescription("");
      setItemAmount(0);
    } else {
      // todo: display toast
    }
  }

  const handleRemoveItem = useCallback((itemId) => {
    setItems(prevItems => prevItems.filter(item => item.id !== itemId));
  }, []);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) {
      return; // Dropped outside the list
    }

    // First sort items based on dragged  order
    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    // Update rank field for all items
    const updatedItems = newItems.map((item, index) => ({
      ...item,
      rank: index,
    }));

    setItems(updatedItems);
  }, [items]);

  // Calculate list total
  const calculateTotal = useCallback(() => { //Calculate total
    return items.reduce((sum, item) => sum + parseFloat(item.price || 0), 0);
  }, [items]);

  return (
     <Root>
       <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
         <DialogTitle>
           <div style={{display: 'flex', justifyContent: 'space-between'}} className={classes.titleContainer}>
             <span>Edit Priority List</span>
             <Typography variant="subtitle1" sx={{color: 'theme.palette.text.secondary',}}>
               Total: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotal())}
             </Typography>
           </div>
         </DialogTitle>
         <DialogContent>
           {/*Title Input*/}
           <Grid container>
             <Grid item size={6}>
               <TextField
                  id="list-item"
                  label="List title"
                  fullWidth
                  variant="standard"
                  value={title}
                  onChange={handleTitleChange}
               />
             </Grid>
           </Grid>
           {/*New Input*/}
           <Grid container sx={{ paddingTop: "5px" }} rowSpacing={2} columnSpacing={2} alignItems={"center"}>
             <Grid item size={6}>
               <TextField
                  id="list-item"
                  label="Description"
                  fullWidth
                  variant="standard"
                  value={itemDescription}
                  onChange={handleAddItemDescriptionChange}
               />
             </Grid>
             <Grid item xs={4}>
               <TextField
                  id="list-item-amount"
                  label="Amount"
                  type='number'
                  variant="standard"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={itemAmount === 0 ? "" : itemAmount}
                  onChange={handleAddItemAmountChange}
               />
             </Grid>
             <Grid item xs={2}>
               <Fab color="primary" aria-label="add" onClick={handleAddItem}>
                 <AddIcon />
               </Fab>
             </Grid>
           </Grid>

           {/*Display list*/}
           <DragDropContext onDragEnd={onDragEnd}>
             <Droppable droppableId="droppable-list">
               {(provided) => (
                  <List sx={{
                    maxHeight: '600px',
                    overflowY: 'auto',
                    width: '100%',
                    scrollbarWidth: 'thin',
                  }} ref={provided.innerRef} {...provided.droppableProps}>
                    {items.map((item, index) => (
                       <Draggable key={item.id} draggableId={item.id} index={index}>
                         {(provided) => (
                            <ListItem
                               key={item.id}
                               ref={provided.innerRef}
                               {...provided.draggableProps}
                               {...provided.dragHandleProps}
                               sx={{
                                 mb: 1, // Margin between items
                                 '&:hover': {
                                   backgroundColor: 'action.hover',
                                 },
                                 userSelect: 'none',
                                 display: 'flex', // Use flexbox for layout
                                 alignItems: 'center', // Vertically center items
                               }}
                            >
                              <ListItemIcon>
                                <DragHandleIcon />
                              </ListItemIcon>
                              <ListItemText
                                 primary={item.description}
                                 secondary={`$${item.price}`}  // Display price
                                 sx={{ flexGrow: 1 }} // Allow text to take up remaining space
                              />
                              <IconButton
                                 edge="end"
                                 aria-label="delete"
                                 onClick={() => handleRemoveItem(item.id)} // Pass item.id
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItem>
                         )}
                       </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
               )}
             </Droppable>
           </DragDropContext>

         </DialogContent>
         <DialogActions>
           <Button onClick={props.closeHandler}>Cancel</Button>
           <Button color='secondary' onClick={updateList}>Save</Button> {/*call save list function*/}
         </DialogActions>
       </Dialog>
     </Root>
  );

  function updateList() {
    let reqHeaders = getTokenHeader();
    reqHeaders = {...reqHeaders, "Content-Type": "application/json"};
    console.log(props.id);

    fetch(appConfig.host + '/api/v1/priority-list', {
      method: 'PUT',
      headers: reqHeaders,
      body: JSON.stringify({
        id: props.id,
        title: title,
        items: items,
        accountId: user.accountId
      })
    }).then(function (response) {
      // Exit and require login
      if(response.status === 401) {
        setPasswordExpired(true);
      }
      handleClose();
    }).catch(function (error) {
        console.error('Request failure: ', error);
    });
  }

  function provideIdsForItems(items) {
    // When priorities page loads there will be no selected list
    if(items === undefined) {
      return;
    }
    // If there are items they should be an array
    if (!Array.isArray(items)) {
      throw new TypeError("Items must be an array");
    }

    return items.map(item => {
      if (item && typeof item === 'object') { // Important: Check for null/undefined items AND that it's an object
        if (item.id === undefined || item.id === null) {
          // Create a *copy* with the new ID.  This is crucial for immutability.
          return { ...item, id: uuidv4() };
        } else {
          return item; // Already has an ID, return as-is
        }
      } else {
        // Handle non-object items (or null/undefined) gracefully.
        // You might throw an error, return a default object, or skip the item.
        // Here, we throw an error, mirroring the Java example's exception.
        throw new TypeError("Each item in the array must be an object");
      }
    });
  }

}



