import Grid from "@mui/material/Grid2";
import React, {useContext, useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import {AuthContext} from "../../context/authContext";
import {UserContext} from "../../context/userContext";
import {Navigate} from "react-router-dom";
import LandingHeader from "./LandingHeader";
import {Card} from "@mui/material";
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import * as settings from "../../utils/settings";
import ForgotPasswordModal from "./ForgotPasswordModal";

const PREFIX = 'Login';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   loginFormContainer: `${PREFIX}-loginFormContainer`,
   loginBoxContainer: `${PREFIX}-loginBoxContainer`,
   loginBoxWrapper: `${PREFIX}-loginBoxWrapper`,
   loginCard: `${PREFIX}-loginBoxCard`,
   logo: `${PREFIX}-logo`,
   password: `${PREFIX}-password`,
   loginErrors: `${PREFIX}-errors`,
   loginErrorContainer: `${PREFIX}-error-container`,
   spacer: `${PREFIX}-spacer`,
}

const Root = styled('form')(({ theme }) => ({
   [`&.${classes.root}`]: {
      '& .MuiInputBase-root': {
         background: 'white',
      },
   },
   [`& .${classes.paper}`]: {
      textAlign: 'center',
      alrign: 'center',
      color: theme.palette.text.secondary,
   },
   [`& .${classes.loginFormContainer}`]: {
      backgroundColor: "#fafafa",
   },
   [`& .${classes.loginBoxContainer}`]: {
      backgroundColor: theme.palette.primary,
      display: 'flex',
      justifyContent: 'center',

   },
   [`& .${classes.loginBoxWrapper}`]: {
      display: 'flex',
      alignItems: 'center',
      height: '75vh',

   },
   [`& .${classes.loginCard}`]: {
      height: '425px',
      width: '350px',
   },
   [`& .${classes.logo}`]: {
      padding: '12px 12px 12px 20px',
   },
   [`& .${classes.password}`]: {
      marginLeft: '10px',
   },
   [`& .${classes.loginErrorContainer}`]: {
      display: 'flex',
      alignItems: 'center',
      minHeight: '15px'
   },
   [`& .${classes.loginErrors}`]: {
      color: theme.palette.error.main
   },
   [`& .${classes.spacer}`]: {
      marginTop: '25px',
   },
}));


export default function LoginPage(props) {

   // Auth state
   const { setAuthToken, isLoggedIn, setIsLoggedIn }  = useContext(AuthContext);
   const { setUser, setUserLoading} = useContext(UserContext);
   // Form state
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [loginError, setLoginError] = useState('');
   const [openForgotPassword, setOpenForgotPassword] = useState(false);

   // Change handlers
   const handleEmailChange = e => setEmail(e.target.value);
   const handlePasswordChange = e => setPassword(e.target.value);

   const handleForgotPasswordClick = () => setOpenForgotPassword(true);
   const handleCloseForgotPassword = () => setOpenForgotPassword(false);



   useEffect(() => {
      const listener = event => {
         if (event.code === "Enter" || event.code === "NumpadEnter") {
            login();
         }
      };
      document.addEventListener("keydown", listener);
      return () => {
         document.removeEventListener("keydown", listener);
      };
   }, [email, password]);


   if (isLoggedIn) {
      return <Navigate to="/dashboard"/>;
   }

   return (
      <Root className={classes.root}>
         <LandingHeader noButtons={true}></LandingHeader>
            <Grid container className={classes.loginBoxContainer}>
               <div className={classes.loginBoxWrapper}>
                  <Card align={'center'} className={classes.loginCard} >
                     <Grid item size={12}>
                        <Typography variant="h6" mt={'10px'}>Sign In</Typography>
                     </Grid>
                     <Grid item size={12} className={"login"}>
                        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                           <Grid item size={12} style={{paddingLeft: "20px", paddingRight: "20px", paddingBottom: "10px", paddingTop: "10px"}}>
                              <TextField
                                 id="email"
                                 label="Email"
                                 variant="outlined"
                                 onChange={handleEmailChange}
                                 fullWidth
                              />
                           </Grid>
                           <Grid item size={12} style={{paddingLeft: "20px", paddingRight: "20px", paddingBottom: "10px", paddingTop: "10px"}}>
                              <TextField
                                 id="password"
                                 label="Password"
                                 type="password"
                                 variant="outlined"
                                 onChange={handlePasswordChange}
                                 fullWidth
                              />
                           </Grid>
                           <Grid item size={12}>
                              <Grid container sx={{display:'flex', justifyContent:{xs: 'center', md: 'center'}, alignItems:'center'}}>
                                 <Grid item>
                                    <Button onClick={login} variant="contained" fullWidth color="secondary" style={{ marginRight: 20, marginTop: 10, marginBottom: 12} }>
                                       Login
                                    </Button>
                                 </Grid>
                              </Grid>
                           </Grid>
                           <Grid item size={12} className={classes.loginErrorContainer}>
                              <Typography className={classes.loginErrors}>{loginError}</Typography>
                           </Grid>
                           <Grid item>
                              <Grid container sx={{display:'flex', justifyContent:{xs: 'flex-end'}, alignItems:'center'}}>
                                 <Grid item>
                                    <Link onClick={handleForgotPasswordClick} sx={{ margin: '12px', cursor: 'pointer'}}> Forgot password</Link>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>

                     <div className={classes.spacer}><hr style={{width:'90%'}}/></div>

                     <Grid item>
                        <Grid container sx={{display:'flex', justifyContent:{xs: 'flex-end'}, alignItems:'center'}}>
                           <Grid item>
                              <Button variant="contained" color="primary" href="/register" sx={{ margin: '12px' }}>
                                 Register
                              </Button>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Card>
               </div>
            </Grid>
         <ForgotPasswordModal open={openForgotPassword} handleClose={handleCloseForgotPassword}></ForgotPasswordModal>
      </Root>
   );

   function login() {
      let url = new URL(settings.appConfig.authHost + '/login');
      let reqBody = JSON.stringify({"email": email, "password": password});
      let reqHeaders = {"Content-Type": "application/json"};
      fetch(url, {
         method: 'POST',
         headers: reqHeaders,
         body: reqBody
      }).then((response) => {
         if (response.status === 200) {
            return response.json();
         } else {
            throw 'Invalid email or password';
         }
      }).then((body) => {
         setAuthToken(body.token);
         addUserInfo(body.token);
      }).then(() => {
         setIsLoggedIn(true);
      }).catch((error) => {
         console.error(error);
         setLoginError(error);
      });
   }

   function addUserInfo (token) {
      const encoded = token.split(".")[1];
      const decoded = JSON.parse(atob(encoded));
      const parsedUser = {
         userId: decoded.sub,
         fname: decoded.fName,
         role: decoded.role,
         accountId: decoded.accountId
      }

      // Persist to cookie
      let userString = btoa(JSON.stringify(parsedUser));
      Cookies.set("user", userString);
      Cookies.set("token", token);
      // Set in userContext
      setUser(parsedUser);
      setUserLoading(false);
   }
}

