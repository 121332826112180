import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import {InputAdornment} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import {styled} from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import {getLocalizedCalendarDateFromEpochSeconds} from "../../utils/date-helper";

const PREFIX = 'TransactionDetails';
const classes = {
    root: `${PREFIX}-root`
}
const Root = styled('form')(({ theme }) => ({
    [`&.${classes.root}`]: {
        flexGrow: 1,
    },
}))

export default function TransactionDetails(props) {
    const open = props.open;
    const accountUsers = props.accountUsers;
    const accountFamily = props.accountFamily;
    const [combinedFamilyMembers, setCombinedFamilyMembers] = useState([]);
    const handleClose = props.handleClose;
    const transactionData = props.transactionData;
    const handleTransactionDetailEdits = props.handleTransactionDetailChanges;
    const handleTransactionDetailSave = props.handleTransactionDetailSave;
    const handleTransactionDelete = props.handleTransactionDelete;
    const accountCategories = props.categories;

    // User options async state
    const [usersOpen, setUsersOpen] = React.useState(false);

    // Category options async state
    const [categoriesOpen, setCategoriesOpen] = React.useState(false);
    const categoriesLoading = categoriesOpen && accountCategories.length === 0;

    const handleDateChange = e => {
        // Convert to date epoch seconds for recording to db
        const transactionInstant = new Date(e.target.value).getTime() / 1000;

        const transactionDetailData = {...transactionData, transactionDate: transactionInstant}
        handleTransactionDetailEdits(transactionDetailData);
    }

    const handleAmountChange = e =>  {
        const transactionDetailData = {...transactionData, amount: e.target.value};
        handleTransactionDetailEdits(transactionDetailData);
    }

    const handleTransactionUserChange = (e,v) => {
        const transactionDetailData = { ...transactionData, userId: v.id}
        handleTransactionDetailEdits(transactionDetailData);
    }

    const handleCategoryChange = (e,v) => {
        const transactionDetailData = {...transactionData, category: v.id}
        handleTransactionDetailEdits(transactionDetailData);
    }

    const handleDescriptionChange = e => {
        const transactionDetailData = {...transactionData, description: e.target.value};
        handleTransactionDetailEdits(transactionDetailData);
    }

    useEffect(() => {
            // combine lists into single group for user select
            const purchasers = [];
            accountUsers.forEach((user) => purchasers.push({id: user.id, firstName: user.firstName}));
            accountFamily.forEach((member) => purchasers.push({id: member.id, firstName: member.firstName}));
            setCombinedFamilyMembers(purchasers);
    }, [accountUsers, accountFamily]);

    return (
       <Root>
            <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
                <DialogTitle>Transaction Details</DialogTitle>
                <DialogContent>
                    <Grid container rowSpacing={1} columnSpacing={2} alignItems={"center"}>
                        <Grid item size={{ xs: 12, sm: 6 }}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <TextField
                                    id="date"
                                    label="Transaction Day"
                                    type="date"
                                    defaultValue={getLocalizedCalendarDateFromEpochSeconds(transactionData.transactionDate)}
                                    onChange={handleDateChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item size={{ xs: 12, sm: 6 }}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Amount"
                                    value={transactionData.amount}
                                    onChange={handleAmountChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item size={{ xs: 12, sm: 6 }}>
                            <div>
                                <Autocomplete
                                   id="transaction-user"
                                   open={usersOpen}
                                   onOpen={() => {
                                       setUsersOpen(true);
                                   }}
                                   onClose={() => {
                                       setUsersOpen(false);
                                   }}
                                   isOptionEqualToValue={(option, value) => option.id === value.id}
                                   getOptionLabel={option => option?.firstName || "User"}
                                   options={combinedFamilyMembers}
                                   onChange={handleTransactionUserChange}
                                   value={combinedFamilyMembers.find(member => member.id === transactionData.userId) || null}
                                   renderInput={params => (
                                      <TextField
                                         {...params}
                                         label="User"
                                         fullWidth
                                         variant="outlined"
                                      />
                                   )}
                                />
                            </div>
                        </Grid>

                        <Grid item size={{ xs: 12, sm: 6 }}>
                            <div>
                                <Autocomplete
                                   freeSolo
                                   id="transaction-tag"
                                   disableClearable
                                   open={categoriesOpen}
                                   onOpen={() => {
                                       setCategoriesOpen(true);
                                   }}
                                   onClose={() => {
                                       setCategoriesOpen(false);
                                   }}
                                   getOptionLabel={option => option.category}
                                   onChange={handleCategoryChange}
                                   options={accountCategories}
                                   loading={categoriesLoading}
                                   value={accountCategories.find(category => category.id === transactionData.category) || null}
                                   renderInput={params => (
                                      <TextField
                                         {...params}
                                         label="Purchase Category"
                                         variant="outlined"
                                         fullWidth
                                         InputProps={{ ...params.InputProps, type: 'search' }}
                                      />
                                   )}
                                />
                            </div>
                        </Grid>
                        <Grid item size={12}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel htmlFor="outlined-adornment-amount">Description</InputLabel>
                                <OutlinedInput
                                   id="outlined-adornment-amount"
                                   label="Description"
                                   value={transactionData.description}
                                   onChange={handleDescriptionChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={handleTransactionDelete}>Delete</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color='secondary' onClick={handleTransactionDetailSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </Root>
    );
}