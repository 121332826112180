import Grid from "@mui/material/Grid2";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useNavigate, useSearchParams} from "react-router-dom";
import LandingHeader from "./LandingHeader";
import {Card, InputAdornment} from "@mui/material";
import * as settings from "../../utils/settings";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const PREFIX = 'PasswordReset';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   loginFormContainer: `${PREFIX}-loginFormContainer`,
   loginBoxContainer: `${PREFIX}-loginBoxContainer`,
   loginBoxWrapper: `${PREFIX}-loginBoxWrapper`,
   loginCard: `${PREFIX}-loginBoxCard`,
   logo: `${PREFIX}-logo`,
   password: `${PREFIX}-password`,
   loginErrors: `${PREFIX}-errors`,
   loginErrorContainer: `${PREFIX}-error-container`,
   spacer: `${PREFIX}-spacer`,
}

const Root = styled('form')(({ theme }) => ({
   [`&.${classes.root}`]: {
      '& .MuiInputBase-root': {
         background: 'white',
      },
   },
   [`& .${classes.paper}`]: {
      textAlign: 'center',
      alrign: 'center',
      color: theme.palette.text.secondary,
   },
   [`& .${classes.loginFormContainer}`]: {
      backgroundColor: "#fafafa",
   },
   [`& .${classes.loginBoxContainer}`]: {
      backgroundColor: theme.palette.primary,
      display: 'flex',
      justifyContent: 'center',

   },
   [`& .${classes.loginBoxWrapper}`]: {
      display: 'flex',
      alignItems: 'center',
      height: '75vh',

   },
   [`& .${classes.loginCard}`]: {
      height: '425px',
      width: '350px',
   },
   [`& .${classes.logo}`]: {
      padding: '12px 12px 12px 20px',
   },
   [`& .${classes.password}`]: {
      marginLeft: '10px',
   },
   [`& .${classes.loginErrorContainer}`]: {
      display: 'flex',
      alignItems: 'center',
      minHeight: '15px'
   },
   [`& .${classes.loginErrors}`]: {
      color: theme.palette.error.main
   },
   [`& .${classes.spacer}`]: {
      marginTop: '25px',
   },
}));


export default function PasswordResetPage(props) {
   const [searchParams] = useSearchParams();
   const navigate = useNavigate();
   // Form state
   const [email, setEmail] = useState(searchParams.get('email') || '');
   const [resetCode, setResetCode] = useState(searchParams.get('resetCode') || '');
   const [newPassword, setNewPassword] = useState('');
   const [resetError, setResetError] = useState('');
   const [showPassword, setShowPassword] = useState(false);
   const [newPasswordTouched, setNewPasswordTouched] = useState(false);
   const [isValid, setIsValid] = useState(true);

   // Change handlers
   const handleEmailChange = e => {
      setEmail(e.target.value);
   };
   const handleResetCodeChange = e => {
      setResetCode(e.target.value);
   };
   const handleNewPasswordChange = e => {
      setNewPassword(e.target.value);
      setNewPasswordTouched(true);
      setIsValid(validatePasswordComplexity(e.target.value));
   };

   const handleClickShowPassword = () => setShowPassword(!showPassword);

   function validatePasswordComplexity(passwordInput) {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      passwordRegex.test(passwordInput);
      return passwordRegex.test(passwordInput);
   }

   function resetPassword() {
      let url = new URL(settings.appConfig.authHost + '/account/password-update');
      let reqBody = JSON.stringify({"email": email, "code": resetCode, "newPassword": newPassword});
      let reqHeaders = {"Content-Type": "application/json"};
      fetch(url, {
         method: 'POST',
         headers: reqHeaders,
         body: reqBody
      }).then((response) => {
         if (response.status === 200) {
            // Navigate back to landing page.
            navigate('/');
         } else {
            setResetError("Unable to reset password")
         }
      });
   }

   // Enable button only if all fields are valid
   const isResetDisabled =
      !email ||
      !resetCode ||
      !newPassword ||
      !validatePasswordComplexity(newPassword);

   return (
      <Root className={classes.root}>
         <LandingHeader noButtons={true}></LandingHeader>
            <Grid container className={classes.loginBoxContainer}>
               <div className={classes.loginBoxWrapper}>
                  <Card align={'center'} className={classes.loginCard} >
                     <Grid size={12}>
                        <Typography variant="h6" mt={'10px'}>Reset Password</Typography>
                     </Grid>
                     <Grid size={12} className={"login"}>
                        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                           <Grid size={12} style={{paddingLeft: "20px", paddingRight: "20px", paddingBottom: "10px", paddingTop: "10px"}}>
                              <TextField
                                 id="email"
                                 label="Email"
                                 variant="outlined"
                                 onChange={handleEmailChange}
                                 fullWidth
                                 value={email}
                              />
                           </Grid>
                           <Grid size={12} style={{paddingLeft: "20px", paddingRight: "20px", paddingBottom: "10px", paddingTop: "10px"}}>
                              <TextField
                                 id="code"
                                 label="Reset Code"
                                 variant="outlined"
                                 onChange={handleResetCodeChange}
                                 fullWidth
                                 value={resetCode}
                              />
                           </Grid>
                           <Grid size={12} style={{paddingLeft: "20px", paddingRight: "20px", paddingBottom: "10px", paddingTop: "10px"}}>
                              <TextField
                                 id="password"
                                 label="New Password"
                                 type={showPassword ? "text" : "password"}
                                 variant="outlined"
                                 onChange={handleNewPasswordChange}
                                 fullWidth
                                 error={!isValid}
                                 helperText={!isValid ? 'Password must be at least 8 characters and have upper and lower case characters' : ''}
                                 InputProps={{ // Add InputProps
                                    endAdornment: (
                                       <InputAdornment position="end">
                                          <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={handleClickShowPassword}
                                          >
                                             {showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                       </InputAdornment>
                                    )
                                 }}
                              />
                           </Grid>
                           <Grid size={12}>
                              <Grid container sx={{display:'flex', justifyContent:{xs: 'center', md: 'center'}, alignItems:'center'}}>
                                 <Grid>
                                    <Button
                                       onClick={resetPassword}
                                       variant="contained"
                                       fullWidth
                                       color="secondary"
                                       disabled={isResetDisabled}
                                       style={{ marginRight: 20, marginTop: 10, marginBottom: 12}}>
                                       Reset Password
                                    </Button>
                                 </Grid>
                              </Grid>
                           </Grid>
                           <Grid size={12} className={classes.loginErrorContainer} sx={{display: "flex", justifyContent: "center"}}>
                              <Typography className={classes.loginErrors}>{resetError}</Typography>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Card>
               </div>
            </Grid>
      </Root>
   );
}

