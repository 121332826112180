import React, {useContext, useEffect, useState} from "react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import TransactionInput from "../Transactions/TransactionInput/TransactionInput";
import {styled} from '@mui/material/styles'
import {Doughnut} from 'react-chartjs-2';
import formatters from "../../utils/formatter";
import {appConfig, getTokenHeader} from "../../utils/settings";

import {
   ArcElement,
   BarElement,
   CategoryScale,
   Chart as ChartJS,
   Legend,
   LinearScale,
   LineElement,
   PointElement,
   TimeScale,
   Title,
   Tooltip,
} from 'chart.js';
import {Fab, Tooltip as MUITooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {AccountContext} from "../../context/accountContext";
import {UserContext} from "../../context/userContext";
import Typography from "@mui/material/Typography";
import DateYearSelection from "./DateYearSelection";
import BudgetStatusBanner from "../shared/BudgetStatusBanner";
import AppDrawer from "../AppDrawer/AppDrawer";
import {LineChart} from "@mui/x-charts/LineChart";
import {getDailyDatesForMonth} from "../../utils/date-helper";
import {AuthContext} from "../../context/authContext";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ArcElement,
    BarElement
);

const PREFIX = 'Dashboard';
const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    lineGraph: `${PREFIX}-lineGraph`,
    floatingAddBtn: `${PREFIX}-floatingAddBtn`,
}
const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
       display: "flex",
       paddingTop: '64px'
    },
    [`& .${classes.paper}`]: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        width: '100%'
    },
    [`& .${classes.lineGraph}`]: {
        minHeight: "200px"
    },
   [`& .${classes.floatingAddBtn}`]: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
   },
}))


export default function Dashboard(props) {
  const {setPasswordExpired} = useContext(AuthContext);
  const {user, userLoading, selectedMonth, selectedYear, setSelectedMonth, setSelectedYear} = useContext(UserContext);
  const {accountCategories, loadingAccountCategories} = useContext(AccountContext);
  const [budgets, setBudgets] = React.useState([]);
  const [budgetsLoading, setBudgetsLoading] = React.useState(false);
  const [transactionDataLoading, setTransactionDataLoading] = useState(true);
  const [transactionData, setTransactionData] = useState([]);

  const [donutGraphDatasets, setDonutGraphDatasets] = useState([]);
  const [loadingCategoryBudgetDatasets, setLoadingCategoryBudgetDatasets] = useState(true);
  const [donutGraphComponents, setDonutGraphComponents] = useState(null);

  const [spendAcrossCategoriesDataset, setSpendAcrossCategoriesDataset] = useState({datasets: []});


  const [dataset, setDataset] = useState([]);
  const [categoryMap, setCategoryMap] = useState({});

  // Transaction Input Modal
   const [openTransactionInputModal, setOpenTransactionInputModal] = React.useState(false);
   const handleOpenTransactionInputModal = () => setOpenTransactionInputModal(true);
   const handleCloseTransactionInputModal = () => setOpenTransactionInputModal(false);

   const handleMonthChange = e => { setSelectedMonth(e.target.value)}
   const handleYearChange = e => setSelectedYear(e.target.value);



   // Custom settings for line graph
   const customize = {
      height: 450,
      legend: { hidden: true },
      margin: { left: 65, right: 30, top: 25, bottom: 30 },
   };

  const spendingByCategoryOptions = {
    plugins: {
      legend: {display: false},
      title: {
        display: true,
        text: 'Spend per Category',
      },
    }
  }

  // Main total and per category line graph
   useEffect(() => {
      if(!transactionDataLoading && !budgetsLoading) {
         setDataset(formatters.generateTransactionDataset(transactionData, budgets));
         console.log(dataset);
      }
   }, [transactionData, accountCategories, budgets]);

  // A handy map of categories
   useEffect(() => {
      const map = accountCategories.reduce((acc, category) => {
         acc[category.id] = category;
         return acc;
      }, {});
      setCategoryMap(map);
      console.log(map);
   }, [accountCategories]);

   useEffect(() => {
      setTransactionDataLoading(false);
   }, [transactionData]);

  // Transaction data async load
   useEffect(() => {
      if(!userLoading) {
         (async () => {
            const options = {method: "GET", headers: getTokenHeader()};
            const response = await fetch(appConfig.host +'/api/v1/transactions/' + selectedMonth + '/' + selectedYear, options);
            // Exit and require login
            if(response.status === 401) {
               setPasswordExpired(true);
            }
            const transactions = await response.json();
            setTransactionData(transactions);
         })();
      }
  }, [selectedMonth, selectedYear, userLoading, transactionDataLoading]);

   // Category Budgets async load to populate total budget for graphs
   useEffect(() => {
      if(!userLoading) {
         (async () => {
            const options = {method: "GET", headers: getTokenHeader()};
            const response = await fetch(appConfig.host +'/api/v1/category/budgets', options);
            // Exit and require login
            if(response.status === 401) {
               setPasswordExpired(true);
            }
            const budgets = await response.json();
            setBudgets(budgets);
            setBudgetsLoading(false);
         })();
      }
   }, [budgetsLoading, loadingAccountCategories, userLoading]);

   useEffect(() => {
      if(accountCategories.length > 0 && budgets.length > 0) {
        const chartData = generateDonutChartData(transactionData, accountCategories, calculateBudgetSum(budgets), categoryMap);
        setSpendAcrossCategoriesDataset(chartData);
      }
   }, [transactionData, accountCategories, budgets]);

   // Format donut graph data
   // Transactions are optional but categories and budgets required for render
   useEffect(() => {
      if(accountCategories.length > 0 && budgets.length > 0) {
         const formattedDataSets = createDonutGraphDatasets(transactionData, accountCategories, budgets)
         setDonutGraphDatasets(formattedDataSets);
         setLoadingCategoryBudgetDatasets(false);
      }
   }, [transactionData, accountCategories, budgets]);

   // Dynamically build chart components per budget category
   useEffect(() => {
      if(!loadingCategoryBudgetDatasets) {
         const budgetGraphs = donutGraphDatasets.map((dataset, index) => (
            <Grid item size={{xs:6, sm:3}} key={index}>
               <Grid><Typography>{dataset.category}</Typography></Grid>
               <Grid><Doughnut data={dataset} /></Grid>
            </Grid>
         ));
         setDonutGraphComponents(budgetGraphs);
      }
   }, [donutGraphDatasets, loadingCategoryBudgetDatasets]);



   function calculateBudgetSum(budgets){
      const sum = budgets.reduce((accumulator, currentValue) => {
         return accumulator + currentValue.categoryBudget
      }, 0)
      return sum;
   }

  function calculateTransactionsSum(transactionData) {
     const sum = transactionData.reduce((accumulator, currentValue) => {
       return accumulator + currentValue.amount
     }, 0)
    return sum;
  }

   function filterAndFormatDataForDonutGraph(categoryBudgetId, transactions, categories, categoryBudgets) {
      // Filter transactions based on the provided category budget ID
      const filteredTransactions = transactions.filter((transaction) => {
         const category = categories.find((cat) => cat.id === transaction.category);
         const categoryBudget = categoryBudgets.find((budget) => budget.id === categoryBudgetId);

         return category !== undefined && categoryBudget !== undefined && category.id === categoryBudget.categoryId;
      });


      // Calculate total spending for the selected category
      const totalSpending = filteredTransactions.reduce((sum, transaction) => sum + transaction.amount, 0);

      // Calculate the remaining budget for the selected category
      const categoryBudget = categoryBudgets.find((budget) => budget.id === categoryBudgetId);
      const remainingBudget = categoryBudget ? categoryBudget.categoryBudget - totalSpending : 0;

      const optCategoryName = categories.find((category) => category.id === categoryBudget.categoryId);
      const categoryName = optCategoryName === undefined ? "unknown": optCategoryName.category;

      const remainingPositiveBalanceColor = '#80cbc4';
      const remainingPositiveBalanceColorHover = '#4db6ac';

      const remainingNegativeBalanceColor = '#f44336';
      const remainingNegativeBalanceColorHover = '#b71c1c';

      const remainingBudgetColor = remainingBudget > 0 ? remainingPositiveBalanceColor: remainingNegativeBalanceColor;
      const remainingBudgetColorHover = remainingBudget > 0 ? remainingPositiveBalanceColorHover: remainingNegativeBalanceColorHover;
      const remainingOrOverageLabel = remainingBudget > 0 ? 'Remaining': 'Overage';

      // Whether this is an overage negative number or positive balance this should always be displayed as positive.
      // The label overage or remaining will indicate pos/neg
      const alwaysPositiveRemainingBudget = Math.abs(remainingBudget);

      // Format data for Chart.js donut graph
      const chartData = {
         category: categoryName,
         labels: ['Spent', remainingOrOverageLabel],
         datasets: [
            {
               data: [totalSpending, alwaysPositiveRemainingBudget],
               backgroundColor: ['#ef9a9a', remainingBudgetColor],
               hoverBackgroundColor: ['#e57373', remainingBudgetColorHover],
            },
         ],
      };
      return chartData;
   }

   function createDonutGraphDatasets(transactions, categories, categoryBudgets) {
      const datasets = [];

      // Iterate through each category budget
      categoryBudgets.forEach((budget) => {
         const categoryBudgetId = budget.id;

         // Filter and format data for the current category budget
         const donutChartData = filterAndFormatDataForDonutGraph(
            categoryBudgetId,
            transactions,
            categories,
            categoryBudgets
         );

         // Add the dataset to the datasets array
         datasets.push(donutChartData);
      });
      return datasets;
   }


   return (
      <Root className={classes.root}>
        <AppDrawer/>
         <Paper className={classes.paper}>
            {/*Transaction Input Modal*/}
            <TransactionInput open={openTransactionInputModal} accountId={user?.accountId} serverUrl={appConfig.host} categories={accountCategories} handleClose={handleCloseTransactionInputModal} saveHandler={setTransactionDataLoading}/>

             <Grid container alignItems="center">
                <Grid size={{ lg: 6, md: 6, sm: 12}}>
                   <DateYearSelection month={selectedMonth} year={selectedYear} handleMonthChange={handleMonthChange} handleYearChange={handleYearChange}/>
                </Grid>

                <Grid size={6} className={classes.floatingAddBtn}>
                   <MUITooltip title={"Add Transaction"}>
                      <Fab color="secondary" onClick={handleOpenTransactionInputModal}  aria-label="add">
                         <AddIcon />
                      </Fab>
                   </MUITooltip>
                </Grid>
             </Grid>


             <BudgetStatusBanner totalBudget={calculateBudgetSum(budgets)} totalTransactions={calculateTransactionsSum(transactionData)}></BudgetStatusBanner>


            <Grid container spacing={2}>
               {/*Line graph of budget over time*/}
               <Grid size={{ xs: 12, lg: 8}}>
                  <Paper className={classes.lineGraph}>
                     {transactionDataLoading ? null: (
                        <LineChart
                           xAxis={[{
                              scaleType: 'time',
                              dataKey: 'date',
                              valueFormatter: (value) => {
                                 return new Date(value).toLocaleDateString('en-US', {
                                    month: '2-digit',
                                    day: '2-digit',
                                 });
                              },
                           }]}
                           yAxis={[{ valueFormatter: formatters.formatCurrency }]}
                           series={[
                              ...Object.keys(dataset[0] || {})
                                 .filter((key) => key !== 'date' && key !== 'total')
                                 .map((categoryId) => ({
                                    dataKey: categoryId,
                                    label: categoryMap[categoryId].category || categoryId,
                                    showMark: false,
                                    color: categoryMap[categoryId].color || formatters.getRandomChartColor(),
                                 })),
                              {
                                 dataKey: 'total',
                                 label: 'Total Remaining Budget',
                                 showMark: true,
                                 color: '#4db6ac'
                              },
                           ]}
                           dataset={dataset}
                           {...customize}
                        />
                     )}
                  </Paper>
               </Grid>

               {/*Across category spend*/}
               <Grid size={{xs: 12, md: 6, lg: 4 }}>
                  <Paper style={{paddingBottom: "8px", minHeight: "450px"}}>
                    <Doughnut data={spendAcrossCategoriesDataset} options={spendingByCategoryOptions}/>
                  </Paper>
               </Grid>
            </Grid>

            {/*Graph per category*/}
            <Grid container style={{justifyContent: "center", paddingTop: "25px", paddingBottom: "15px"}}>
              <Grid><Typography variant="h5">Category Budgets</Typography></Grid>
            </Grid>
            <Grid container size={12}>
               {donutGraphComponents}
            </Grid>
            <div style={{ padding: 15}}></div>
         </Paper>
      </Root>
    );

  function generateDonutChartData(transactions, accountCategories, totalBudget, categoryMap) {
    // Calculate total spend per category
    const categoryTotals = {};

    if(transactions.length > 0) {
       transactions.forEach(transaction => {
          const categoryId = transaction.category;
          const transactionAmount = transaction.amount;

          if (categoryTotals[categoryId] === undefined) {
             categoryTotals[categoryId] = 0;
          }

          categoryTotals[categoryId] += transactionAmount;
       });
    }

    // Prepare data for Chart.js
    const chartData = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [], // You can customize colors here
      }]
    };

    // Handle no spend for month case
    if(Object.keys(categoryTotals).length === 0) {
       console.log("Apply empty state graph");
       chartData.labels.push("You've spent nothing!")
       chartData.datasets[0].data.push(totalBudget);
       chartData.datasets[0].backgroundColor.push(formatters.getRandomChartColor());
    } else {
       // Populate chartData with category names, spending, and percentages
       Object.keys(categoryTotals).forEach(categoryId => {
          const categoryName = accountCategories.find(category => category.id === categoryId).category;
          const spending = categoryTotals[categoryId];
          //const percentage = (spending / totalBudget) * 100;

          chartData.labels.push(categoryName);
          chartData.datasets[0].data.push(spending);
          chartData.datasets[0].backgroundColor.push(categoryMap[categoryId].color || formatters.getRandomChartColor()); // Function to generate random colors
       });
    }
    return chartData;
  }
}

