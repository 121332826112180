export function getDailyDatesForMonth(month, year) {
   /**
    * Generates a comma-separated string of dates for every day in the given month and year.
    *
    * @param {number} month The month (1-12).
    * @param {number} year The year (e.g., 2024).
    * @returns {string} A comma-separated string of dates in DD format.
    */

   const dates = [];
   const firstDayOfMonth = new Date(year, month - 1, 1); // Months are 0-indexed
   const nextMonth = new Date(year, month, 1);
   const daysInMonth = (nextMonth - firstDayOfMonth) / (1000 * 60 * 60 * 24);

   for (let day = 1; day <= daysInMonth; day++) {
      dates.push(new Date(year, month - 1, day));
   }

   return dates;
}

export function formatDateToDDMM(date) {
   const day = String(date.getDate()).padStart(2, '0');
   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
   return `${month}/${day}`;
}

export function getTodaysDateForInput() {
   const today = new Date();
   const year = today.getFullYear();
   const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
   const day = String(today.getDate()).padStart(2, '0');
   return `${year}-${month}-${day}`;
}

export function getLocalizedDateFromEpochSeconds(epochSeconds) {
   const epochMs = epochSeconds * 1000;
   const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

   const date = new Date(epochMs + tzOffset);
   return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
}

// We store dates as unix epoch seconds which is recorded in Java as an Instant and contains no localized tz.
// The js Date object will translate that back into localized Date for us.
export function getLocalizedCalendarDateFromEpochSeconds(epochSeconds){
   const epochMs = epochSeconds * 1000;
   const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
   const date = new Date(epochMs - tzOffset);

   var year = date.getFullYear();
   var month = date.getMonth()+1;
   var day = date.getDate();

   if(day < 10) {
      day = "0" + day;
   }
   if(month < 10) {
      month = "0" + month;
   }

   return year + "-" + month + "-" + day;
}