import React, {useContext, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import './RegistrationPage.css';
import RegistrationHeader from "./RegistrationHeader";
import swoop from '../../images/belliercurve1920.png';
import {AuthContext} from "../../context/authContext";
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {appConfig, getTokenHeader} from "../../utils/settings";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import {InputAdornment, Link, Switch} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {validateEmail, validatePasswordComplexity, validatePasswordsMatch} from "../../utils/validation";

const settings = require("../../utils/settings");
const PREFIX = 'Registration';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   textField: `${PREFIX}-textField`,
   extraLongInput: `${PREFIX}-extraLongInput`,
   sectionHeader: `${PREFIX}-sectionHeader`,
   divider: `${PREFIX}-divider`,
   register: `${PREFIX}-register`,
   registerCancelBtn: `${PREFIX}-registerCancelBtn`,
   accountInfo: `${PREFIX}-account-info`
}
const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
      flexGrow: 1,
   },
   [`& .${classes.paper}`]: {
      color: theme.palette.text.secondary,
      width: '100%'
   },
   [`& .${classes.extraLongInput}`]: {
       width: "100%"
   },
   [`& .${classes.sectionHeader}`]: {
      padding: "10px",
      marginBottom: "10px"
   },
   [`& .${classes.divider}`]: {
      marginTop: "25px"
   },
   [`& .${classes.register}`]: {
      marginTop: "16px",
      marginBottom: "50px",
      display: "flex",
      justifyContent: "right",
      paddingLeft: "5px",
   },
   [`& .${classes.registerCancelBtn}`]: {
      paddingRight: "5px",
   },
   [`& .${classes.accountInfo}`]: {
      marginTop: "25px"
   },
}))

export default function RegistrationPage(props) {
   // Auth status
   const {setAuthToken} = useContext(AuthContext);
   const navigate = useNavigate();
   const authUrl = appConfig.authHost;

   // Form state
   //Required
   const [email, setEmail] = useState('');
   const [emailAvailable, setEmailAvailable] = useState(true);
   const [emailLabel, setEmailLabel] = useState('Email');

   const [password, setPassword] = useState('');
   const [pwConfirmation, setPwConfirmation] = useState('');
   const [showPassword, setShowPassword] = React.useState(false);
   const [showConfirmationPassword, setShowConfirmationPassword] = React.useState(false);
   const [passwordLabel, setPasswordLabel] = useState('Password (Min 8 characters)');
   const [firstname, setFirstname] = useState('');
   const [lastname, setLastname] = useState('');
   const [phoneNumber, setPhoneNumber] = useState('');

   const [requiredFieldsSet, setRequiredFieldsSet] = useState(false);
   const [emailIsValid, setEmailIsValid] = useState(false);
   const [passwordsMatch, setPasswordsMatch] = useState(false);
   const [passwordIsValid, setPasswordIsValid] = useState(false);
   const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);

   // Optional
   const [accountName, setAccountName] = useState('');
   const [accountAddress, setAccountAddress] = useState('');
   const [accountCity, setAccountCity] = useState('');
   const [accountState, setAccountState] = useState('');
   const [accountZip, setAccountZip] = useState('');

   // Legal accept
   const [acceptedEULA, setAcceptedEULA] = useState(false);
   const [acceptDataPrivacy, setAcceptedDataPrivacy] = useState(false);

   // Form submission state
   const [isSaving, setIsSaving] = useState(false);
   // Only present error validations after giving them a chance rather than starting out all red
   const [hasSubmitted, setHasSubmitted] = useState(false);

   // Required user handlers
   const handleFirstnameChange = e => setFirstname(e.target.value);
   const handleLastnameChange = e => setLastname(e.target.value);
   const handleEmailChange = e => {
      setEmail(e.target.value);
   }
   const handlePhoneNumberChange = e => {
      setPhoneNumber(e.target.value);
   }
   const handlePasswordChange = e => {
      setPassword(e.target.value);
      setPasswordIsValid(validatePasswordComplexity(e.target.value));
   }
   const handlePwConfirmationChange = e => {
      setPwConfirmation(e.target.value);
      setPasswordsMatch(validatePasswordsMatch(password, e.target.value));
   }

   // Optional account handlers
   const handleAccountNameChange = e => setAccountName(e.target.value);
   const handleAccountAddressChange = e => setAccountAddress(e.target.value);
   const handleAccountCityChange = e => setAccountCity(e.target.value);
   const handleAccountStateChange = e => setAccountState(e.target.value);
   const handleAccountZipChange = e => setAccountZip(e.target.value);

   const handleClickShowPassword = () => setShowPassword((show) => !show);
   const handleClickShowConfirmationPassword = () => setShowConfirmationPassword((show) => !show);
   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };

   const handleAcceptEULAChange = (e) => {
      setAcceptedEULA(e.target.checked);
   }
   const handleAcceptDataPrivacyChange = (e) => {
      setAcceptedDataPrivacy(e.target.checked);
   }


   // Registration enable/disable checks
   // Catches any state change to targeted state fields and then calls subsequent validity check
   useEffect(() => {
      setRequiredFieldsSet(isRequiredFieldSet);
   }, [email, password, pwConfirmation, firstname, lastname, phoneNumber, acceptedEULA, acceptDataPrivacy, emailIsValid, emailAvailable]);

   // email validity checks
   useEffect(() => {
      let valid = validateEmail(email);
      setEmailIsValid(valid);
   }, [email]);

   // phoneNumber validity checks
   useEffect(() => {
      setPhoneNumberIsValid(phoneNumber.length >= 10);
   }, [phoneNumber]);

   useEffect(() => {
      if(email && emailIsValid)
         (async () => {
            const options = {method: "GET", headers: getTokenHeader()};
            const response = await fetch(authUrl +'/api/v1/accounts/check/' + email, options);
            const emailAvailableStatus = await response.json();
            setEmailAvailable(emailAvailableStatus);
            if(emailAvailableStatus) {
               setEmailLabel("Email")
            } else {
               setEmailLabel("Email already in use");
            }
         })();
   }, [email, emailIsValid]);

   // need a way to display that password is not complex enough but only display when appropriate
   // after submission try.
   // useEffect(() => {
   //    if(hasSubmitted) {
   //       setPasswordLabel('')
   //    }
   // }, [password]);


   return (
      <Root className={classes.root}>
         <Paper className={classes.paper}>
            <Grid container className={"registration-container"}>
               <RegistrationHeader></RegistrationHeader>
               <Grid size={12} className={"registration-header"}>
                  <Typography variant={"h2"} color={"primary"} align={"center"}>
                     We make budgeting easy.
                  </Typography>
               </Grid>
               <Grid container className={"swoop"}>
                  <img src={swoop} alt="swoop" width="100%" height="auto"/>
               </Grid>


               <Container maxWidth={"md"}>
                  <Grid size={12}><Typography align={"center"} variant={"h5"} color={"primary"} className={classes.sectionHeader}>Create
                     a user </Typography></Grid>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                     <Grid size={12}>
                        <TextField id="email" label={emailLabel} variant="outlined"
                                   onChange={handleEmailChange} className={classes.extraLongInput}
                                   error={(!emailIsValid && hasSubmitted) || !emailAvailable}/>
                     </Grid>
                     <Grid size={{xs:12, md: 3}}>
                        <TextField id="firstname" label="First Name" variant="outlined" fullWidth
                                   className={classes.textField} onChange={handleFirstnameChange}/>
                     </Grid>
                     <Grid size={{xs:12, md: 3}}>
                        <TextField id="lastname" label="Last Name" variant="outlined" fullWidth
                                   className={classes.textField}
                                   onChange={handleLastnameChange}/>
                     </Grid>
                     <Grid size={{ xs: 12, md: 6 }}>
                        <TextField id="phone" label="Phone" variant="outlined" fullWidth
                                   className={classes.textField}
                                   onChange={handlePhoneNumberChange}
                                   error={!phoneNumberIsValid && hasSubmitted}/>

                     </Grid>
                     <Grid size={{ xs: 12, md: 6 }}>
                        <FormControl fullWidth variant="outlined" onChange={handlePasswordChange} error={!passwordIsValid && hasSubmitted}>
                           <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                           <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword ? 'text' : 'password'}
                              endAdornment={
                                 <InputAdornment position="end">
                                    <IconButton
                                       aria-label="toggle password visibility"
                                       onClick={handleClickShowPassword}
                                       onMouseDown={handleMouseDownPassword}
                                       edge="end"
                                    >
                                       {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                 </InputAdornment>
                              }
                              label="Password"
                           />
                        </FormControl>
                     </Grid>
                     <Grid size={{ xs: 12, md: 6 }}>
                        <FormControl fullWidth variant="outlined" onChange={handlePwConfirmationChange} error={!passwordsMatch && hasSubmitted}>
                           <InputLabel htmlFor="outlined-adornment-password">Confirm</InputLabel>
                           <OutlinedInput
                              id="outlined-adornment-password"
                              type={showConfirmationPassword ? 'text' : 'password'}
                              endAdornment={
                                 <InputAdornment position="end">
                                    <IconButton
                                       aria-label="toggle password visibility"
                                       onClick={handleClickShowConfirmationPassword}
                                       onMouseDown={handleMouseDownPassword}
                                       edge="end"
                                    >
                                       {showConfirmationPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                 </InputAdornment>
                              }
                              label="Confirm"
                           />
                        </FormControl>
                     </Grid>
                  </Grid>


                  <Grid size={12} align={"center"} className={classes.sectionHeader}><Typography align={"center"} variant={"h5"} color={"primary"} className={classes.accountInfo}>Optional Account
                     Information</Typography></Grid>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                     <Grid size={{ xs: 12, md: 6 }}><TextField id="accountName" label="Account Name" variant="outlined"
                                                          className={classes.extraLongInput} onChange={handleAccountNameChange}/></Grid>
                     <Grid size={{ xs: 12, md: 6 }}><TextField id="accountAddress" label="Address" variant="outlined"
                                                          className={classes.extraLongInput}
                                                          onChange={handleAccountAddressChange}/></Grid>
                     <Grid size={{ xs: 12, md: 4 }}><TextField id="city" label="City" variant="outlined"
                                                          className={classes.extraLongInput} onChange={handleAccountCityChange}/></Grid>
                     <Grid size={{ xs: 12, md: 4 }}><TextField id="accountState" label="State" variant="outlined"
                                                          className={classes.extraLongInput}
                                                          onChange={handleAccountStateChange}/></Grid>
                     <Grid size={{ xs: 12, md: 4 }}><TextField id="accountZip" label="Zip" variant="outlined"
                                                          className={classes.extraLongInput} onChange={handleAccountZipChange}/></Grid>
                  </Grid>
                  <Grid container rowSpacing={1} sx={{paddingTop: "10px"}}>
                     <Grid size={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link component={RouterLink} to="/eula" target="_blank" rel="noopener">
                           I have read and accept the EULA <OpenInNewIcon fontSize="small" />
                        </Link>

                        <Switch
                           checked={acceptedEULA}
                           onChange={handleAcceptEULAChange}
                           slotProps={{
                              input: { 'aria-label': 'controlled' }
                           }}
                        />

                     </Grid>
                     <Grid size={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link component={RouterLink} to="/data-privacy" target="_blank" rel="noopener">
                           I have read and accept the Data Privacy Policy <OpenInNewIcon fontSize="small" />
                        </Link>
                        <Switch
                           checked={acceptDataPrivacy}
                           onChange={handleAcceptDataPrivacyChange}
                           slotProps={{
                              input: { 'aria-label': 'controlled' }
                           }}
                        />

                     </Grid>
                  </Grid>




                  <Grid container size={12} align={"center"} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                     <Grid size={3} className={classes.register}>
                        <div className={classes.registerCancelBtn}>
                           <Button variant="contained" color="secondary" onClick={navigateHome} >
                              Cancel
                           </Button>
                        </div>
                        <div>
                           <LoadingButton loading={isSaving} disabled={!requiredFieldsSet} variant="contained" color="secondary" onClick={createAccount}>
                              Register
                           </LoadingButton>
                        </div>
                     </Grid>
                  </Grid>
               </Container>
            </Grid>
         </Paper>
      </Root>
   );

   function createAccount() {
      // Should mark errors on fields
      setHasSubmitted(true);

      if(isValidated()) {
         setIsSaving(true);

         let createAccountPayload = JSON.stringify({
            account: {
               "name": accountName,
               "address": accountAddress,
               "city": accountCity,
               "state": accountState,
               "zip": accountZip,
            },
            accountOwner: {
               "firstName": firstname,
               "lastName": lastname,
               "email": email,
               'phone': phoneNumber,
               "tz": "UTC",
               "role": "OWNER",
            },
            password:  password
         });

         fetch(settings.appConfig.authHost+ '/account', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: createAccountPayload
         }).then(response => response.json()).then((body) => {
            setAuthToken(body.token);
            setIsSaving(false);
         }).then(() => {
            navigate('/login');
         }).catch(function (error) {
            console.error('Request failure: ', error);
         });
      } else {
         // display field errors toast.
         console.error("fields set but not valid")
      }
   }

   function navigateHome() {
      navigate('/login');
   }


   // Gates whether we allow submission
   function isRequiredFieldSet() {
      let emailSet = email != null && email != '';
      let firstNameSet = firstname != null && firstname !== ''
      let lastNameSet = lastname != null && lastname !== ';'
      let phoneNumberSet = phoneNumber != null && phoneNumber !== '';
      let pwSet = password != null && password !== '';
      let pwConfirmationSet = pwConfirmation != null && pwConfirmation !== '';
      const allowed = emailSet && firstNameSet
         && lastNameSet && phoneNumberSet
         && pwSet && pwConfirmationSet
         && acceptedEULA && acceptDataPrivacy
         && emailIsValid && emailAvailable;
      console.log(allowed);
      return allowed;
   }

   // Gates whether submission goes through and enables field errors
   function isValidated() {
      return emailIsValid && passwordIsValid && phoneNumberIsValid && passwordsMatch && isRequiredFieldSet();
   }
}



