import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import RegistrationHeader from "../RegistrationPage/RegistrationHeader";
import swoop from '../../images/belliercurve1920.png';
import {AuthContext} from "../../context/authContext";
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {appConfig, getTokenHeader} from "../../utils/settings";


const PREFIX = 'EULA';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   sectionHeader: `${PREFIX}-sectionHeader`,
   divider: `${PREFIX}-divider`,
}
const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
      flexGrow: 1,
   },
   [`& .${classes.paper}`]: {
      color: theme.palette.text.secondary,
      width: '100%'
   },
   [`& .${classes.divider}`]: {
      marginTop: "25px"
   }
}))

export default function EULA(props) {
   // Auth status
   const navigate = useNavigate();

   return (
      <Root className={classes.root}>
         <Paper className={classes.paper}>
            <Grid container className={"registration-container"}>
               <RegistrationHeader></RegistrationHeader>
               <Grid item size={12} className={"registration-header"}>
                  <Typography variant={"h2"} color={"primary"} align={"center"}></Typography>
               </Grid>
               <Grid container className={"swoop"}>
                  <img src={swoop} alt="swoop" width="100%" height="auto"/>
               </Grid>


               <Container maxWidth={"md"}>
                  <Grid item size={12}><Typography align={"center"} variant={"h5"} color={"primary"} className={classes.sectionHeader}>End User License Agreement (EULA) for Frugal</Typography></Grid>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                     <Typography variant="h5" gutterBottom>
                        This End-User License Agreement (EULA) is a legal agreement between you (either an individual or a single entity) and Deremworks LLC for the use of the frugal software application (the "Application"). By downloading, installing, accessing, or using the Application, you agree to be bound by the terms and conditions of this EULA. If you do not agree to the terms of this EULA, do not download, install, access, or use the Application.
                     </Typography>

                     <Typography variant="h3" gutterBottom>
                        1. Grant of License
                     </Typography>

                     <Typography variant="h5" gutterBottom>
                        Deremworks LLC grants you a non-exclusive, non-transferable, revocable license to use the Application solely for your personal, non-commercial use in the United States. You may not:
                        <ul>
                           <li>Modify, adapt, translate, or create derivative works based on the Application.</li>
                           <li>Reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code of the Application.</li>
                           <li>Rent, lease, sell, sublicense, distribute, or otherwise transfer the Application to any third party.</li>
                           <li>Use the Application for any illegal or unauthorized purpose.</li>
                           <li>Use the Application to violate any applicable laws or regulations.</li>
                        </ul>
                     </Typography>

                     <Typography variant="h3" gutterBottom>
                        2. Acceptable Use
                     </Typography>

                     <Typography variant="h5" gutterBottom>
                        The Application is intended for personal budgeting purposes only. You agree to use the Application only for lawful purposes and in a manner that does not infringe the rights of, or restrict or inhibit the use and enjoyment of, the Application by any third party.
                     </Typography>

                     <Typography variant="h3" gutterBottom>
                        3. User Responsibilities
                     </Typography>

                     <Typography variant="h5" gutterBottom>
                        <ul>
                           <li>
                              **Password Security:** You are solely responsible for maintaining the confidentiality of your password and account information. You agree to choose a strong and secure password and to take all necessary precautions to prevent unauthorized access to your account.
                           </li>
                           <li>
                              **Data Accuracy:** You are solely responsible for the accuracy and completeness of the data you enter into the Application. Deremworks LLC is not responsible for any errors or omissions in the data.
                           </li>
                           <li>
                              **Backup:** You are solely responsible for backing up your data. Deremworks LLC is not responsible for any loss of data due to any reason.
                           </li>
                        </ul>
                     </Typography>

                     <Typography variant="h3" gutterBottom>
                        4. Disclaimer of Warranties
                     </Typography>

                     <Typography variant="h5" gutterBottom>
                        THE APPLICATION IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. DEREMWORKS LLC DOES NOT WARRANT THAT THE APPLICATION WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE APPLICATION IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                     </Typography>

                     <Typography variant="h3" gutterBottom>
                        5. Limitation of Liability
                     </Typography>

                     <Typography variant="h5" gutterBottom>
                        IN NO EVENT SHALL DEREMWORKS LLC BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE APPLICATION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
                     </Typography>

                     <Typography variant="h3" gutterBottom>
                        6. Governing Law
                     </Typography>

                     <Typography variant="h5" gutterBottom>
                        This EULA shall be governed by and construed in accordance with the laws of the State of Arizona, without regard to its conflict of laws principles.
                     </Typography>

                     <Typography variant="h3" gutterBottom>
                        7. Entire Agreement
                     </Typography>

                     <Typography variant="h5" gutterBottom>
                        This EULA constitutes the entire agreement between you and Deremworks LLC with respect to the Application and supersedes all prior or contemporaneous communications and proposals, whether oral or written.
                     </Typography>

                     <Typography variant="h3" gutterBottom>
                        8. Severability
                     </Typography>

                     <Typography variant="h5" gutterBottom>
                        If any provision of this EULA is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall remain in full force and effect.
                     </Typography>

                     <Typography variant="h3" gutterBottom>
                        9. Termination
                     </Typography>

                     <Typography variant="h5" gutterBottom>
                        This EULA is effective until terminated by either party. You may terminate this EULA at any time by ceasing to use the Application. Deremworks LLC may terminate this EULA at any time for any reason, with or without notice.
                     </Typography>

                     <Typography variant="h3" gutterBottom>
                        10. US Residents Only
                     </Typography>

                     <Typography variant="h5" gutterBottom>
                        The Application is intended for use by US residents only. By using the Application, you represent and warrant that you are a US resident.
                     </Typography>

                     {/*<Typography variant="h3" gutterBottom>*/}
                     {/*   11. Contact Information*/}
                     {/*</Typography>*/}

                     {/*<Typography variant="h5" gutterBottom>*/}
                     {/*   If you have any questions about this EULA, please contact Deremworks LLC at [Your Contact Information].*/}
                     {/*</Typography>*/}

                     <Typography variant="h5" gutterBottom>
                        By using the Application, you acknowledge that you have read this EULA, understand it, and agree to be bound by its terms and conditions.
                     </Typography>
                  </Grid>
               </Container>
            </Grid>
         </Paper>
      </Root>
   );

}



