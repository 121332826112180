import React, {useState, useEffect, useContext} from 'react';
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import {Icon, Rating, Snackbar} from "@mui/material";
import Button from "@mui/material/Button";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@mui/styles';
import AppDrawer from "../AppDrawer/AppDrawer";
import ConstructionIcon from '@mui/icons-material/Construction';
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {appConfig, getTokenHeader} from "../../utils/settings";
import {UserContext} from "../../context/userContext";
import {green} from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import {LineChart} from "@mui/x-charts/LineChart";
import {axisClasses} from "@mui/x-charts";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {AuthContext} from "../../context/authContext";

const PREFIX = 'DashboardDashboard';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
}
const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
      display: "flex",
      paddingTop: '64px'
   },
   [`& .${classes.paper}`]: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%'
   },
}))

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
   aboutContainer: {
      padding: theme.spacing(3),
   },
   image: {
      maxWidth: '100%',
      height: 'auto',
      margin: theme.spacing(2, 0),
   },
   ratingContainer: {
      margin: theme.spacing(2, 0),
   },
   feedbackContainer: {
      padding: '25px'
   }
}));


export default function Contact(props) {
   const {setPasswordExpired} = useContext(AuthContext);
   const serverUrl = appConfig.host;
   const {user, userLoading} = useContext(UserContext);
   const [rating, setRating] = useState(0);
   const [feedback, setFeedback] = useState("");
   const [loading, setLoading] = React.useState(false);
   const [success, setSuccess] = React.useState(false);
   const steps = ['$0: No Revenue', ' $100: Hosting is paid',];

   const buttonSx = {
      ...(success && {
         bgcolor: green[500],
         '&:hover': {
            bgcolor: green[700],
         },
      }),
   };


   const handleRatingChange = (event, value) => {
      setRating(value);
   };
   const handleFeedbackChange = e => {
      setFeedback(e.target.value);
   }


   // Handle feedback submission
   const handleSendFeedback = () => {
      // Add your logic here to handle the feedback submission

      if (!loading) {
         setSuccess(false);
         setLoading(true);
         setTimeout(() => {
            setSuccess(true);
            setLoading(false);
         }, 45000);
      }

      let reqHeaders = getTokenHeader();
      reqHeaders = {...reqHeaders, "Content-Type": "application/json"};
      var response = fetch(serverUrl +'/api/v1/customerFeedback', {
         method: 'POST',
         headers: reqHeaders,
         body: JSON.stringify({
            userId: user.userId,
            accountId: user.accountId,
            rating: rating,
            feedback: feedback
         })
      }).then(function(response) {
         // Exit and require login
         if(response.status === 401) {
            setPasswordExpired(true);
         }
      }).catch(function (error) {
         setLoading(false);
         console.error('Submit feedback failure: ', error);
      });

   };

   // Use the styles
   const classes2 = useStyles();



   return (
      <Root className={classes.root}>
         <AppDrawer/>
         <Paper className={classes.paper}>
            <Grid container>
               <div className={classes2.aboutContainer}>
                  <Typography variant="h4" gutterBottom>
                     About Our Budgeting App
                  </Typography>
                  <Typography paragraph>
                     <ConstructionIcon/> Constructive criticism accepted. Tell me how you really feel. If you'd like to consider supporting, please buy me a coffee.
                  </Typography>

                  <Grid item>
                        <Typography variant="h6" gutterBottom>
                           Rate Our App
                        </Typography>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                           <Rating
                              name="rating"
                              value={rating}
                              precision={1}
                              onChange={handleRatingChange}
                           />
                        </Box>
                  </Grid>
                  <Grid item className={classes2.feedbackContainer}>
                     <TextField
                        id="outlined-multiline-static"
                        label="Feedback"
                        multiline
                        rows={4}
                        defaultValue=""
                        style={{width: "100%"}}
                        onChange={handleFeedbackChange}
                     />
                  </Grid>
                  <Grid item>
                     <Box sx={{display: 'flex', justifyContent: 'flex-end', paddingTop: '15px'}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                           <Button variant="contained" sx={buttonSx} color="primary" disabled={loading} onClick={handleSendFeedback}>Submit Feedback</Button>
                           {loading && (
                              <CircularProgress
                                 size={24}
                                 sx={{
                                    color: green[500],
                                    position: 'relative',
                                    top: 5,
                                    left: -92,
                                    zIndex: 1,
                                 }}
                              />
                           )}
                        </Box>
                     </Box>
                  </Grid>
               </div>
            </Grid>

            <Grid container>
               <Grid item size={12}>
                  <Typography variant="h4" gutterBottom>
                     Transparent Revenue
                  </Typography>
               </Grid>

               <Grid item size={12}>
                  <Paper sx={{padding: "15px"}}>
                     <Stepper activeStep={0}>
                        {steps.map((label, index) => {
                           const stepProps = {};
                           const labelProps = {};
                           return (
                              <Step key={label} {...stepProps}>
                                 <StepLabel {...labelProps}>{label}</StepLabel>
                              </Step>
                           );
                        })}
                     </Stepper>
                  </Paper>
               </Grid>
            </Grid>
         </Paper>
      </Root>
   );
}