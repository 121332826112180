import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import {InputAdornment} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";


export default function CategoryBudgetDetails(props) {
    const open = props.open;
    const handleClose = props.handleClose;
    const category = props.categoryName;
    const categoryBudgetDetailData = props.categoryBudgetDetailData;
    const handleCategoryBudgetDetailEdits = props.handleCategoryBudgetDetailChanges;
    const handleCategoryBudgetDetailSave = props.handleCategoryBudgetDetailSave;
    const handleCategoryBudgetDelete = props.handleCategoryBudgetDelete;

    const handleCategoryBudgetAmountChange = e =>  {
        const categoryBudgetDetailDataUpdates = {...categoryBudgetDetailData, categoryBudget: e.target.value};
        handleCategoryBudgetDetailEdits(categoryBudgetDetailDataUpdates);
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
                <DialogTitle>Category Budget Details</DialogTitle>
                <DialogContent>
                    <Grid container rowSpacing={1} columnSpacing={2} alignItems={"center"}>
                        <Grid item size={{ xs: 12, sm: 6 }}>
                            <Typography>{category}</Typography>
                        </Grid>
                        <Grid item size={{ xs: 12, sm: 6 }}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Amount"
                                    value={categoryBudgetDetailData.categoryBudget}
                                    onChange={handleCategoryBudgetAmountChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={handleCategoryBudgetDelete}>Delete</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color='secondary' onClick={handleCategoryBudgetDetailSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}