import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import {styled} from "@mui/material/styles";
import {appConfig} from "../../utils/settings";
import CircularProgress from "@mui/material/CircularProgress";
import {validateEmail} from "../../utils/validation";

const PREFIX = 'ForgotPasswordModal';
const classes = {
   root: `${PREFIX}-root`
}
const Root = styled('form')(({ theme }) => ({
   [`&.${classes.root}`]: {
      flexGrow: 1,
   },
}))

export default function ForgotPasswordModal(props) {
   const serverUrl = appConfig.authHost;
   const open = props.open;
   const [email, setEmail] = useState('');
   const [emailError, setEmailError] = useState(false);
   const [emailTouched, setEmailTouched] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [isSuccess, setIsSuccess] = useState(true);
   const handleClose = props.handleClose;

   const handleRequestCode = async () => {
      setIsLoading(true);
      if(validateEmail(email)) {
         let reqHeaders = {"Content-Type": "application/json"};

         const response = await fetch(serverUrl + '/account/password-reset/', {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify({
               email: email,
            })
         });

         setIsLoading(false);

         if(response.status === 200) {
            setIsSuccess(true);
         } else {
            setIsSuccess(false);
         }
         localHandleClose()
      }
      // Maybe toast if not sent or error?

   };

   const handleEmailChange = e => {
      setEmail(e.target.value);
      setEmailTouched(true);
   }

   const isRequestCodeDisabled = emailTouched && !validateEmail(email);

   const localHandleClose = () => {
      setEmail('');
      setEmailError(false);
      setEmailTouched(false);
      handleClose();
   }

   return (
      <Root>
         <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
            <DialogTitle>Request Password Reset Code</DialogTitle>
            <DialogContent>
               <Grid container rowSpacing={1} columnSpacing={2} alignItems={"center"}>
                  <Grid size={12}>
                     <FormControl fullWidth sx={{ m: 1 }}>
                        <TextField
                           autofocus
                           id="email"
                           label="Email Address"
                           type="email"
                           fullWidth
                           variant="standard"
                           value={email}
                           onChange={handleEmailChange}
                           error={emailError && emailTouched}
                           helperText={emailError && emailTouched ? 'Invalid email address' : ''}
                        />
                     </FormControl>
                  </Grid>
               </Grid>

            </DialogContent>
            <DialogActions>
               <Button onClick={localHandleClose}>Cancel</Button>
               <Button variant="contained" color='secondary' disabled={isRequestCodeDisabled || isLoading} onClick={handleRequestCode}>
                  {isLoading ? ( // Conditionally render loading indicator or text
                     <CircularProgress size={24} color="inherit" />
                  ) : (
                     'Request Code'
                  )}
               </Button>
            </DialogActions>
         </Dialog>
      </Root>
   );
}