import React, {useState, useEffect, useContext} from 'react';
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@mui/styles';
import AppDrawer from "../AppDrawer/AppDrawer";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Slider from '@mui/material/Slider';
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { LineChart } from '@mui/x-charts/LineChart';
import {Box} from "@mui/material";
import {axisClasses} from "@mui/x-charts";

const PREFIX = 'MortgageCalculator';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
}
const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
      display: "flex",
      paddingTop: '64px'
   },
   [`& .${classes.paper}`]: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%'
   },
}))

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
   mortgageCalcContainer: {
      paddingTop: "40px"
   }
}));


export default function MortgageCalculator(props) {
   const [calculateLoan, setCalculateLoan] = useState('off');
   const [displayCalcLoan, setDisplayCalcLoan] = useState("none");

   const [sellPrice, setSellPrice] = useState(0);
   const [purchasePrice, setPurchasePrice] = useState(0);
   const [loanAmount, setLoanAmount] = useState(0);
   const [annualInterestRate, setAnnualInterestRate] = useState(6.5);
   const [loanTerm, setLoanTerm] = useState(0);
   const [monthlyPayment, setMonthlyPayment] = useState(0);
   const [totalInterestPaid, setTotalInterestPaid] = useState(0);
   const [mrMoneyBags, setMrMoneyBags] = useState(false);

   const handleCurrentHomeSellPriceChange = e => {
      setSellPrice(e.target.value);
   }
   const handleNewHomePurchasePriceChange = e => {
      setPurchasePrice(e.target.value);
   }

   const handleLoanAmountChange = e => {
      // core loan calc stuff
      const loanAmount = parseInt(e.target.value);
      setLoanAmount(loanAmount);
   };
   const handleInterestRateChange = e => {
      setAnnualInterestRate(parseInt(e.target.value));
   };
   const handleLoanTermChange = e => {
      setLoanTerm(parseInt(e.target.value));
   };
   const handleToggleChange = e => {
      const currentValue = calculateLoan;
      const newValue = calculateLoan === 'off'? 'on': 'off';
      setCalculateLoan(newValue);

      if(newValue === 'on') {
         setDisplayCalcLoan('flex');
      } else {
         setDisplayCalcLoan('none');
      }
   }

   useEffect(() => {
      // if were looking at home loans more than 10mil you should be buying cash
      if(loanAmount > 10000000) {
         setMrMoneyBags(true);
      } else {
         setMrMoneyBags(false);
      }
   }, [loanAmount]);

   useEffect(() => {
      // MonthlyPayment = P [ i(1 + i)^n ] / [ (1 + i)^n – 1]
      // P = principle
      // i = interest rate / 12
      // n = number of monthly payments
      if(loanAmount > 0 && annualInterestRate > 0 && loanTerm > 0) {
         // Main calculation
         const loanTermMonths = loanTerm * 12;
         const monthlyInterestRate = annualInterestRate / 100 / 12;
         const monthly = getMonthlyPayment(loanAmount, monthlyInterestRate, loanTermMonths);

         if(monthly) {
            setMonthlyPayment(monthly)
         } else {
            setMonthlyPayment(0);
         }
      }
   }, [loanAmount, annualInterestRate, loanTerm]);


   // Calculate total interest paid
   useEffect(() => {
      if(loanAmount > 0 && monthlyPayment > 0 && loanTerm > 0) {
         const loanTermMonths = loanTerm * 12;
         const totalPaid = monthlyPayment * loanTermMonths;
         const totalInterest = totalPaid - loanAmount;
         setTotalInterestPaid(totalInterest.toFixed(2));
      }
   }, [monthlyPayment, loanAmount, loanTerm])

   const loanTermChoices = [
      {
         value: 30,
         label: '30 Year Fixed',
      },
      {
         value: 15,
         label: '15 Year Fixed',
      }
   ];

   function getMonthlyPayment(loanAmount, monthlyInterestRate, loanTermMonths) {
      let amount= (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTermMonths)) /
         (Math.pow(1 + monthlyInterestRate, loanTermMonths) - 1);
      return amount.toFixed(2);
   }

   function rateValueText(value) {
      return `${value}°C`;
   }

   // Use the styles
   const classes2 = useStyles();
   const label = { inputProps: { 'aria-label': 'Switch demo' } };

   return (
      <Root className={classes.root}>
         <AppDrawer/>
         <Paper className={classes.paper}>
            <Typography variant="h4">Mortgage Calculator</Typography>
            <Grid container rowSpacing={2} columnSpacing={2} className={classes2.mortgageCalcContainer}>
               <Grid item size={{ xs: 12, sm: 6 }}>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                     <Grid item size={{ sm: 12, md: 6 }}>
                        <FormControl fullWidth variant="outlined">
                           <InputLabel htmlFor="outlined-adornment-amount">Loan Amount</InputLabel>
                           <OutlinedInput
                              label="Loan Amount"
                              id="outlined-adornment-amount"
                              type='number'
                              inputProps={{'inputMode':'numeric', 'pattern':'[0-9]*'}}
                              value={loanAmount === 0 ? "": loanAmount}
                              onChange={handleLoanAmountChange}
                           />
                        </FormControl>
                     </Grid>
                     <Grid item size={{ sm: 12, md: 6 }}>
                        <FormControl>
                           <TextField
                              id="outlined-select-term"
                              select
                              label="Select"
                              defaultValue='30'
                              value={loanTerm}
                              onChange={handleLoanTermChange}
                              helperText="Please select your loan term"
                              fullWidth
                           >
                              {loanTermChoices.map((option) => (
                                 <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                 </MenuItem>
                              ))}
                           </TextField>
                        </FormControl>
                     </Grid>
                  </Grid>
                  <Grid item size={12}>
                     <Box sx={{padding: "15px"}}>
                        <Typography sx={{fontWeight: 600}} id="input-slider" gutterBottom>
                           Interest Rate
                        </Typography>
                        <Slider
                           aria-label="Rate"
                           defaultValue={6.5}
                           getAriaValueText={rateValueText}
                           step={0.25}
                           marks
                           min={0}
                           max={12}
                           valueLabelDisplay="auto"
                           onChange={handleInterestRateChange}
                           color="secondary"
                        />
                     </Box>
                  </Grid>
               </Grid>
               <Grid item size={{ xs: 12, sm: 6 }} sx={{borderLeft: "solid 3px #4db6ac"}}>
                  <Box sx={{padding: "15px"}}>
                     <Typography sx={{fontSize: "24px"}}>Monthly payment</Typography>
                     <Typography sx={{fontSize: "20px", fontWeight: 500}}>${monthlyPayment}</Typography>
                  </Box>
                  <Box sx={{padding: "15px"}}>
                     <Typography sx={{fontSize: "24px"}}>Total Interest</Typography>
                     <Typography sx={{fontSize: "20px", fontWeight: 500}}>${totalInterestPaid}</Typography>
                  </Box>
               </Grid>
               <Grid item size={12}>
                  <Paper sx={{display: mrMoneyBags? 'flex': 'none'}}>
                     <Typography sx={{padding: "15px"}}> Alright moneybags, you should be buying cash.</Typography>
                  </Paper>
               </Grid>
            </Grid>


         </Paper>
      </Root>
   );
}