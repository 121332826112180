import Cookies from "js-cookie";

export var appConfig = {
   // Checkout span in minutes
   defaultCheckout: 1440,

   // prod
   authHost: "https://auth.kuriotech.com",
   host: "https://frugal-services-sncliebqsa-uw.a.run.app",

   // local dev
   //authHost: "http://192.168.1.234:9087",
   //host: "http://192.168.1.234:8080",


   parseToken: function() {
      const encoded = this.token.split(".")[1];
      return JSON.parse(atob(encoded));
   }
};

export function getTokenHeader() {
   return {"Authorization": "Bearer " + Cookies.get('token')};
}

export function clearCookies() {
   Cookies.remove("token");
   Cookies.remove("user");
}