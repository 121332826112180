import React, {useState, useEffect, useContext} from 'react';
import Paper from '@mui/material/Paper';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import './TransactionInput.css';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import {getTokenHeader} from "../../../utils/settings";
import {UserContext} from "../../../context/userContext";
import {AccountContext} from "../../../context/accountContext";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {getTodaysDateForInput} from "../../../utils/date-helper";
import {AuthContext} from "../../../context/authContext";


const PREFIX = 'TransactionInput';
const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  rightIcon: `${PREFIX}-rightIcon`,
}
const Root = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%'
  },
  [`& .${classes.rightIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}))

export default function TransactionInput(props) {
  const open = props.open;
  const {setPasswordExpired} = useContext(AuthContext);
  const setLoadingTransactionData = props.saveHandler;
  const {user} = useContext(UserContext);
  const {accountUsers, accountUsersLoading, accountFamily, accountFamilyLoading} = useContext(AccountContext);
  const [combinedFamilyMembers, setCombinedFamilyMembers] = useState([]);
  const serverUrl = props.serverUrl;
  const handleClose = props.handleClose;
  const accountCategories = props.categories;

  // Category options async state
  const [categoriesOpen, setCategoriesOpen] = React.useState(false);
  const categoriesLoading = categoriesOpen && accountCategories.length === 0;

  // User options async state
  const [usersOpen, setUsersOpen] = React.useState(false);
  const usersLoading = usersOpen && accountUsers.length === 0;

  // Transaction form state
  const[amount, setAmount] = useState(0);
  const[transactionUser, setTransactionUser] = useState('');
  const[category, setCategory] = useState('');
  const[transactionDate, setTransactionDate] = useState(getTodaysDateForInput);
  const[notes, setNotes] = useState('');

  // Change handlers
  const handleAmountChange = e => setAmount(e.target.value);
  const handleTransactionUserChange = (e,v) => setTransactionUser(v?.id);
  const handleCategoryChange = (e,v) => setCategory(v.id);
  const handleTransactionDateChange = e => {
    setTransactionDate(e.target.value);
  }
  const handleNotesChange = e => setNotes(e.target.value);


  // Users options async load
  useEffect(() => {
    let active = true;

    if (!accountUsersLoading) {
      return undefined;
    }

    return () => {
      active = false;
    };
  }, [accountUsersLoading]);

  useEffect(() => {
    if(!usersLoading && !accountFamilyLoading) {
      // combine lists into single group for user select
      const purchasers = [];
      accountUsers.forEach((user) => purchasers.push({id: user.id, firstName: user.firstName}));
      accountFamily.forEach((member) => purchasers.push({id: member.id, firstName: member.firstName}));
      setCombinedFamilyMembers(purchasers);
    }
  }, [usersLoading, accountFamilyLoading]);


  return (
    <Root>
      <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
        <DialogTitle>Add Transaction</DialogTitle>
        <DialogContent>
          <Grid container sx={{paddingTop: "5px"}} rowSpacing={2} columnSpacing={2} alignItems={"center"}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                 id="date"
                 label="Transaction Day"
                 type="date"
                 onChange={handleTransactionDateChange}
                 fullWidth
                 InputLabelProps={{
                   shrink: true,
                 }}
                 value={transactionDate}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                <OutlinedInput
                   label="Amount"
                   id="outlined-adornment-amount"
                   type='number'
                   inputProps={{'inputMode':'numeric', 'pattern':'[0-9]*'}}
                   value={amount === 0 ? "": amount}
                   onChange={handleAmountChange}
                />
              </FormControl>
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <div>
                <Autocomplete
                   id="transaction-user"
                   open={usersOpen}
                   onOpen={() => {
                     setUsersOpen(true);
                   }}
                   onClose={() => {
                     setUsersOpen(false);
                   }}
                   isOptionEqualToValue={(option, value) => option.firstName === value.firstName}
                   getOptionLabel={option => option?.firstName ? option.firstName: "User"}
                   options={combinedFamilyMembers}
                   onChange={handleTransactionUserChange}
                   loading={accountUsersLoading || accountFamilyLoading}
                   renderInput={params => (
                      <TextField
                         {...params}
                         label="User"
                         fullWidth
                         variant="outlined"
                         InputProps={{
                           ...params.InputProps,
                           endAdornment: (
                              <React.Fragment>
                                {(accountUsersLoading || accountFamilyLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                           ),
                         }}
                      />
                   )}
                />
              </div>
            </Grid>

            <Grid item size={{ xs: 12, sm: 6 }}>
              <div>
                <Autocomplete
                   freeSolo
                   id="transaction-tag"
                   disableClearable
                   open={categoriesOpen}
                   onOpen={() => {
                     setCategoriesOpen(true);
                   }}
                   onClose={() => {
                     setCategoriesOpen(false);
                   }}
                   getOptionLabel={option => option.category}
                   onChange={handleCategoryChange}
                   options={accountCategories}
                   loading={categoriesLoading}
                   renderInput={params => (
                      <TextField
                         {...params}
                         label="Purchase Category"
                         variant="outlined"
                         fullWidth
                         InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                   )}
                />
              </div>
            </Grid>
            <Grid item size={12}>
              <TextField
                 id="transaction-notes"
                 label="Notes"
                 placeholder="Purchase Notes"
                 fullWidth
                 InputLabelProps={{
                   shrink: true,
                 }}
                 variant="outlined"
                 onChange={handleNotesChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button color='secondary' onClick={saveTransaction}>Save</Button>
        </DialogActions>
      </Dialog>



    </Root>
  );


  /**
   * Dates suck, but save to db as seconds since epoch and don't worry about tzs.
   * Send to server as ms, translate to Instant server side
   * When it comes back to ui just turn the epoch ms back into a date.
   * It should factor in local date time for the given epoch ms.
   */
  function saveTransaction() {
    // Get the ms since epoch for input
    const epochMs = new Date(transactionDate).getTime();
    const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

    // Convert to epochSeconds when sending to server
    const transactionInstant = new Date(epochMs - tzOffset).getTime() / 1000;
    const postedInstant = (Date.now() - tzOffset) / 1000;


    let reqHeaders = getTokenHeader();
    reqHeaders = {...reqHeaders, "Content-Type": "application/json"};

    fetch(serverUrl + '/api/v1/transactions', {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify({
        userId: transactionUser,
        accountId: user.accountId,
        transactionDate: transactionInstant,
        postedDate: postedInstant,
        description: notes,
        category: category,
        amount: amount
      })
    }).then(function (response) {
      // Exit and require login
      if(response.status === 401) {
        setPasswordExpired(true);
      }
      handleClose();
      // reset some fields, keep date, user, etc
      setNotes('');
      setAmount(0);
      setLoadingTransactionData(true);
    }).catch(function (error) {
      console.error('Request failure: ', error);
    });
  }
}



