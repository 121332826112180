import React, {useContext, useEffect, useState} from 'react';
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';
import AppDrawer from "../AppDrawer";
import {UserContext} from "../../../context/userContext";
import {Box, Fab} from "@mui/material";
import {appConfig, getTokenHeader} from "../../../utils/settings";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {AuthContext} from "../../../context/authContext";

const PREFIX = 'Profile';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   titleRow: `${PREFIX}-titleRow`,
   itemRow: `${PREFIX}-itemRow`,
}
const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
      display: "flex",
      paddingTop: '64px'
   },
   [`& .${classes.paper}`]: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%',
   },

   [`& .${classes.titleRow}`]: {
      display: 'flex',
      justifyContent: 'flex-start'
   },
   [`& .${classes.itemRow}`]: {
      paddingLeft: '10px',
   },
}))


export default function Profile(props) {
   // Use the styles
   const {setPasswordExpired} = useContext(AuthContext);
   const {user, userLoading} = useContext(UserContext);
   const [fullUser, setFullUser] = useState({});
   const [fullUserLoading, setFullUserLoading] = useState(false);

   // Edit dialog params
   const [open, setOpen] = useState(false);
   const [savingEdit, setSavingEdit] = useState(false);
   const [editFirstName, setEditFirstName] = useState('');
   const [editLastName, setEditLastName] = useState('');
   const [editEmail, setEditEmail] = useState('');
   const [editPhone, setEditPhone] = useState('');

   const handleEditClick = () => {
      setOpen(true); // Open the dialog
   };

   const handleClose = () => {
      setOpen(false); // Close the dialog
   };

   const handleSave = () => {
      setSavingEdit(true);
      let reqHeaders = getTokenHeader();
      reqHeaders = {...reqHeaders, "Content-Type": "application/json"};
      fetch(appConfig.authHost +'/api/v1/accounts/user', {
         method: 'PUT',
         headers: reqHeaders,
         body: JSON.stringify({
            accountId: fullUser.accountId,
            id: fullUser.id,
            firstName: editFirstName,
            lastName: editLastName,
            email: editEmail,
            phone: editPhone,
         })
      }).then(function (response) {
         setSavingEdit(false);
         console.log(response);
         if(response.status === 401) {
            setPasswordExpired(true);
         }
         // trigger reload of user info
         setFullUserLoading(true);
      }).catch(function (error) {
         console.error('Request failure: ', error);
      });

      handleClose();
   };

   // Context is lost over app refresh. Reset from cookie if it exists.
   useEffect(() => {
      if (!userLoading) {
         // go retrieve full user
         (async () => {
            const options = { method: "GET", headers: getTokenHeader() };
            try {
               const response = await fetch(
                  appConfig.authHost + '/api/v1/accounts/' + user.accountId + '/user/' + user.userId, options
               );

               if (response.ok) { // check if response is ok before attempting to parse json
                  const fullUser = await response.json();
                  console.log(fullUser);
                  setFullUser(fullUser);
                  setEditEmail(fullUser.email);
                  setEditPhone(fullUser.phone);
                  setEditLastName(fullUser.lastName);
                  setEditFirstName(fullUser.firstName);
               } else if (response.status === 401){
                  setPasswordExpired(true);
               } else {
                  console.error(`Request failed with status: ${response.status}`);
               }

            } catch (error) {
               console.error('Request failure: ', error);
            }
         })();
      }
   }, [userLoading, fullUserLoading]);

   const phoneNumber = fullUser.phone ? fullUser.phone: 'Not set'

   return (
      <Root className={classes.root}>
         <AppDrawer/>
         <Paper className={classes.paper}>
               <div style={{display: 'flex', justifyContent: 'center', paddingTop: '50px'}}>
                  <Box border={1} borderRadius={2} borderColor={'border.default'} sx={{padding: '15px', width: '100%'}}>
                     <Grid container sx={{display: 'flex', alignItems: 'center'}}>
                        <Grid item size={12} className={classes.titleRow}>
                           <Typography variant="h5" sx={{fontWeight: '400', color: 'title.header'}} gutterBottom>
                              Profile Info
                           </Typography>
                           <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end'  }}>
                              <Fab
                                 color="primary"
                                 aria-label="edit"
                                 size="small"
                                 onClick={handleEditClick}
                              >
                                 <EditIcon />
                              </Fab>
                           </Box>
                        </Grid>
                     </Grid>
                     <TableContainer>
                        <Table>
                           <TableRow>
                              <TableCell><Typography sx={{fontSize: 14, fontWeight: '500', color: 'title.subHeader'}}>First Name</Typography></TableCell>
                              <TableCell><Typography sx={{fontWeight: '400', color: 'title.value'}}>{fullUser.firstName}</Typography></TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell><Typography sx={{fontSize: 14, fontWeight: '500', color: 'title.subHeader'}}>Last Name</Typography></TableCell>
                              <TableCell><Typography sx={{fontWeight: '400', color: 'title.value'}}>{fullUser.lastName}</Typography></TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell><Typography sx={{fontSize: 14, fontWeight: '500', color: 'title.subHeader'}}>Email</Typography></TableCell>
                              <TableCell><Typography sx={{fontWeight: '400', color: 'title.value'}}>{fullUser.email != null ? fullUser.email: 'Not Set'}</Typography></TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell><Typography sx={{fontSize: 14, fontWeight: '500', color: 'title.subHeader'}}>Phone</Typography></TableCell>
                              <TableCell><Typography sx={{fontWeight: '400', color: 'title.value'}}>{phoneNumber}</Typography></TableCell>
                           </TableRow>
                        </Table>
                     </TableContainer>

                  </Box>
               </div>

            <Dialog open={open} onClose={handleClose}>
               <DialogTitle>Edit Profile</DialogTitle>
               <DialogContent>
                  <TextField label="First Name" value={editFirstName} onChange={(e) => setEditFirstName(e.target.value)} fullWidth margin="normal" />
                  <TextField label="Last Name" value={editLastName} onChange={(e) => setEditLastName(e.target.value)} fullWidth margin="normal" />
                  <TextField label="Email" value={editEmail} onChange={(e) => setEditEmail(e.target.value)} fullWidth margin="normal" />
                  <TextField label="Phone" value={editPhone} onChange={(e) => setEditPhone(e.target.value)} fullWidth margin="normal" />
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" onClick={handleSave}>Save</Button>
               </DialogActions>
            </Dialog>
         </Paper>
      </Root>
   );


}