import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import RegistrationHeader from "../RegistrationPage/RegistrationHeader";
import swoop from '../../images/belliercurve1920.png';
import {AuthContext} from "../../context/authContext";
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {appConfig, getTokenHeader} from "../../utils/settings";


const PREFIX = 'Legal';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   sectionHeader: `${PREFIX}-sectionHeader`,
   divider: `${PREFIX}-divider`,
}
const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
      flexGrow: 1,
   },
   [`& .${classes.paper}`]: {
      color: theme.palette.text.secondary,
      width: '100%'
   },
   [`& .${classes.divider}`]: {
      marginTop: "25px"
   }
}))

export default function PrivacyPolicyPage(props) {
   // Auth status
   const navigate = useNavigate();

   return (
      <Root className={classes.root}>
         <Paper className={classes.paper}>
            <Grid container className={"registration-container"}>
               <RegistrationHeader></RegistrationHeader>
               <Grid item size={12} className={"registration-header"}>
                  <Typography variant={"h2"} color={"primary"} align={"center"}>

                  </Typography>
               </Grid>
               <Grid container className={"swoop"}>
                  <img src={swoop} alt="swoop" width="100%" height="auto"/>
               </Grid>


               <Container maxWidth={"md"}>
                  <Grid item size={12}><Typography align={"center"} variant={"h5"} color={"primary"} className={classes.sectionHeader}>Privacy Policy </Typography></Grid>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                     <Typography variant="body1" sx={{marginTop: "15px", marginBottom: "15px"}}>
                        This application is intended for use by United States citizens only. By using this application, you acknowledge that it is not designed to comply with the General Data Protection Regulation (GDPR) or any other international privacy laws. If you are not a resident of the United States, it is your responsibility to discontinue use of the application, as any violations of applicable privacy laws outside the U.S. are solely the user's responsibility.
                     </Typography>
                     <Typography variant="body1" sx={{marginTop: "15px", marginBottom: "50px"}}>
                        While there is currently no intention to use user data beyond providing the core functionality of this application, anonymized data may be collected and analyzed in the future to improve services, perform statistical analysis, or for other lawful purposes. By using the application, you consent to this potential future use of anonymized data. Under no circumstances will personally identifiable information (PII) be shared without your explicit consent. The provider of this application is not responsible for any unauthorized access or misuse of anonymized data by third parties to whom this data is legally provided.
                     </Typography>


                  </Grid>
               </Container>
            </Grid>
         </Paper>
      </Root>
   );



   function navigateToDashboard() {
      navigate('/');
   }




}



