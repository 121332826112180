import React from 'react';
import './RegistrationHeader.css';
import logo from '../../images/logo2.png';
import Grid from "@mui/material/Grid";

export default function RegistrationPage(props) {
   return (
      <Grid container className={"registration-header"} alignContent={"center"}>
         <Grid container className={"registration-bar"}>
            <Grid item size={{xs: 12, sm: 4}} className={"logo"} sx={{marginTop: "10px"}}>
               <img src={logo} alt="app logo" width="60" height="50"/>
            </Grid>
         </Grid>
         <Grid container className={"style-hr2"} justify={"flex-end"} alignContent="center"></Grid>
      </Grid>

   );
}
