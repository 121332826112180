import React from 'react';
import logo from '../../images/frugal_logo_with_name.png';

import Cookies from "js-cookie";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {makeStyles} from '@mui/styles';

const settings = require("../../utils/settings");

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
   loginBar: {
      background: '#2c2c2c',
      minHeight: '75px',
   }
}));

export default function LandingHeader(props) {
   const classes = useStyles();
   const noButtons = props?.noButtons ?? false;

   const renderButtons = () => {
      if (noButtons) {
         return null; // Don't render any buttons
      } else {
         return (
            <> {/* Use a Fragment to wrap multiple elements */}
               <Button variant="outlined" color="primary" href="/login" sx={{ margin: '12px' }}>
                  Login
               </Button>
               <Button variant="contained" color="primary" href="/register" sx={{ margin: '12px' }}>
                  Register
               </Button>
            </>
         );
      }
   };

   return (
      <Grid container className={"login-header"}>
         <Grid container className={classes.loginBar} alignItems={"center"}>
            <Grid item size={{ xs: 12, md: 4 }} className={"logo"}>
               <Grid container justifyContent={"flex-start"}>
                  <img src={logo} alt="app logo"/>
               </Grid>
            </Grid>
         </Grid>
         <Grid container className={"style-hr2"} justifyContent={"flex-end"} alignContent={"center"}></Grid>
         <Grid container justifyContent={"flex-end"} sx={{backgroundColor: '#f5f5f5'}}>
            <Grid item>
               {renderButtons()}
            </Grid>
         </Grid>
      </Grid>
   );
}
