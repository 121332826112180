import React, {useCallback, useContext, useEffect} from 'react';
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';
import AppDrawer from "../AppDrawer/AppDrawer";
import {Card, CardHeader, Fab} from "@mui/material";
import {appConfig, getTokenHeader} from "../../utils/settings";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import PriorityListInput from "./PriorityListInput";
import PriorityListEdit from "./PriorityListEdit";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {AuthContext} from "../../context/authContext"; // Import the icon

const PREFIX = 'Priorities';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   floatingAddBtn: `${PREFIX}-floatingAddBtn`,
}
const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
      display: "flex",
      paddingTop: '64px'
   },
   [`& .${classes.paper}`]: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%'
   },
   [`& .${classes.floatingAddBtn}`]: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
   },
}))

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
   prioritiesContainer: {
      paddingTop: "40px"
   },
   titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
   },
   total: {
      marginLeft: theme.spacing(2),
      color: theme.palette.text.secondary,
   },
}));


export default function Priorities(props) {
   const {setPasswordExpired} = useContext(AuthContext);
   const [priorityLists, setPriorityLists] = React.useState([]);
   const [loading, setLoading] = React.useState(true);
   const [openListInputModal, setOpenListInputModal] = React.useState(false);
   const [openListEditModal, setOpenListEditModal] = React.useState(false);
   const [anchorEl, setAnchorEl] = React.useState(null); // State for menu anchor
   const [selectedList, setSelectedList] = React.useState(null); // State to store the selected list
   const [editList, setEditList] = React.useState(null);

   // Use the styles
   const classes2 = useStyles();

   const handleOpenListInputModal = () => setOpenListInputModal(true);
   const handleCloseListInputModal = () => {
      setOpenListInputModal(false);
      setLoading(true);
   }
   const handleCloseListEditModal = () => {
      setOpenListEditModal(false);
      setLoading(true);
   }


   // Load priority lists
   useEffect(() => {
      if(loading) {
         (async () => {
            const options = {method: "GET", headers: getTokenHeader()};
            const response = await fetch(appConfig.host +'/api/v1/priority-list', options);
            // Exit and require login
            if(response.status === 401) {
               setPasswordExpired(true);
            }
            const priorityLists = await response.json();
            setLoading(false);
            setPriorityLists(priorityLists);
         })();
      }
   }, [loading]);

   const handleMenuClick = (event, list) => {
      setAnchorEl(event.currentTarget);
      setSelectedList(list);
      setEditList(list);
   };

   const handleMenuClose = () => {
      setAnchorEl(null);
      setSelectedList(null); // Clear the selected list
   };

   const handleEdit = useCallback(() => {
      setOpenListEditModal(true);
      handleMenuClose();
   }, []);

   const handleDelete = () => {
      // Implement delete logic
      handleMenuClose();

      if (!selectedList) return;

      fetch(`${appConfig.host}/api/v1/priority-list/${selectedList.id}`, {
         method: 'DELETE',
         headers: getTokenHeader(),
      })
         .then(response => {
            // Exit and require login
            if(response.status === 401) {
               setPasswordExpired(true);
            }
            // Update the state to remove the deleted list
            setPriorityLists(prevLists => prevLists.filter(list => list.id !== selectedList.id));
            handleMenuClose();
         })
         .catch(error => {
            console.error('Error deleting list:', error);
            // Optionally, display an error message to the user
         });
   };

   // Calculate list total
   const calculateTotal = useCallback((items) => {
      if (!Array.isArray(items)) {
         return 0; // Return 0 for null or non-array
      }
      return items.reduce((sum, item) => sum + (parseFloat(item.price) || 0), 0);
   },[]);

   return (
      <Root className={classes.root}>
         <AppDrawer />
         <Paper className={classes.paper}>
            <Typography variant="h4">Prioritize your goals</Typography>
            <Grid container rowSpacing={2} columnSpacing={2} className={classes2.prioritiesContainer}>
               <Grid item size={12} className={classes.floatingAddBtn}>
                  <Fab variant="extended" onClick={handleOpenListInputModal} color="secondary" aria-label="add">
                     <AddIcon />
                     Priority List
                  </Fab>
               </Grid>
               {/* Empty lists */}
               {(!priorityLists || priorityLists.length === 0) && ( // Check if priorityLists is null or empty
                  <Grid item size={12}> {/* Full width on all screen sizes */}
                     <div>No priority lists available. Please add some.</div> {/* Or any other content */}
                  </Grid>
               )}
               {/*Display the lists*/}
               {priorityLists.map((priorityList) => (
                  <Grid item size={{ xs: 12, sm: 6, md: 4 }} key={priorityList.id}> {/* Responsive grid */}
                     <Card className={classes2.card}> {/* Apply card styling */}
                        <CardHeader
                           title={
                              <div className={classes2.titleContainer}>
                                 <span>{priorityList.title}</span>
                                 <Typography variant="subtitle1" className={classes2.total}>
                                    Total: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotal(priorityList.items))}
                                 </Typography>
                              </div>
                           }
                           action={
                              <>
                                 <IconButton
                                    aria-label="settings"
                                    onClick={(event) => handleMenuClick(event, priorityList)}
                                 >
                                    <MoreVertIcon />
                                 </IconButton>

                              </>
                           }/>
                        <Menu
                           anchorEl={anchorEl}
                           open={Boolean(anchorEl) && selectedList?.id === priorityList.id} //Conditionally open.
                           onClose={handleMenuClose}
                        >
                           <MenuItem onClick={handleEdit}>Edit</MenuItem>
                           <MenuItem onClick={handleDelete}>Delete</MenuItem>
                        </Menu>
                        <List sx={{
                           maxHeight: '300px',
                           overflowY: 'auto',
                           width: '100%',
                           scrollbarWidth: 'thin',
                        }}>
                           {priorityList.items.sort((a, b) => a.rank - b.rank).map((item) => (
                              <ListItem key={item.id}>
                                 <ListItemText
                                    primary={item.description}
                                    secondary={`$${item.price}`}
                                 />
                              </ListItem>
                           ))}
                        </List>
                     </Card>
                  </Grid>
               ))}
            </Grid>
         </Paper>
         <PriorityListInput open={openListInputModal} closeHandler={handleCloseListInputModal}/>
         <PriorityListEdit open={openListEditModal} closeHandler={handleCloseListEditModal}
                           id={editList?.id}
                           initialTitle={editList?.title}
                           initialItems={editList?.items}/>
      </Root>
   );
}