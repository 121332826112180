import React, {useState, useEffect, useContext} from 'react';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import formatter from "../../utils/formatter";

import {styled} from "@mui/material/styles";
import TransactionDetails from "./TransactionDetails";
import {appConfig, getTokenHeader} from "../../utils/settings";
import {UserContext} from "../../context/userContext";
import {AccountContext} from "../../context/accountContext";
import {Box, Fab, Modal} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TransactionInput from "./TransactionInput/TransactionInput";
import AppDrawer from "../AppDrawer/AppDrawer";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Tooltip from "@mui/material/Tooltip";
import TransactionUploader from "./TransactionUpload/TransactionsUpload";
import DateYearSelection from "../Dashboard/DateYearSelection";
import {getLocalizedDateFromEpochSeconds} from "../../utils/date-helper";
import { DataGrid } from '@mui/x-data-grid';
import {AuthContext} from "../../context/authContext";


const PREFIX = 'Transactions';
const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    timeSelection: `${PREFIX}-timeSelection`,
    floatingAddBtn: `${PREFIX}-floatingAddBtn`,
}
const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        // display: 'flex',
        flexGrow: 1,
        display: "flex",
        paddingTop: '64px'
    },
    [`& .${classes.paper}`]: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        width: '100%'
    },
    [`& .${classes.timeSelection}`]: {
        paddingTop: '15px',
        display: 'flex',
        padding: theme.spacing(2),
    },
    [`& .${classes.floatingAddBtn}`]: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))

export default function Transactions(props) {
    const serverUrl = appConfig.host;
    const {setPasswordExpired} = useContext(AuthContext);
    const {user, selectedMonth, selectedYear, setSelectedMonth, setSelectedYear} = useContext(UserContext);
    const { accountCategories, loadingAccountCategories,
        accountUsers, accountUsersLoading,
        accountFamily, accountFamilyLoading } = useContext(AccountContext);


    const [transactionData, setTransactionData] = useState([]);
    const [openTransactionDetails, setOpenTransactionDetails] = React.useState(false);
    const [transactionDetailData, setTransactionDetailData] = React.useState({});
    const [loadingTransactionData, setLoadingTransactionData] = React.useState(true);
    const [openTransactionsUpload, setOpenTransactionsUpload] = useState(false); // State for modal open/close
    const handleOpenTransactionsUpload = () => setOpenTransactionsUpload(true);
    const handleCloseTransactionsUpload = () => setOpenTransactionsUpload(false);

    // Transaction Input Modal
    const [openTransactionInputModal, setOpenTransactionInputModal] = React.useState(false);
    const handleOpenTransactionInputModal = () => setOpenTransactionInputModal(true);
    const handleCloseTransactionInputModal = () => {
        setOpenTransactionInputModal(false);
    }

    const handleTransactionDetailChanges = (transactionDetailEdits) => {
        setTransactionDetailData(transactionDetailEdits);
    };

    const handleTransactionDetailSave = () => {
        let reqHeaders = getTokenHeader();
        reqHeaders = {...reqHeaders, "Content-Type": "application/json"};
        fetch(serverUrl +'/api/v1/transactions/' + transactionDetailData.id, {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify({
                userId: transactionDetailData.userId,
                accountId: user.accountId,
                transactionDate: transactionDetailData.transactionDate,
                postedDate: transactionDetailData.postedDate,
                description: transactionDetailData.description,
                category: transactionDetailData.category,
                amount: transactionDetailData.amount
            })
        }).then(function (response) {
            // Exit and require login
            if(response.status === 401) {
                setPasswordExpired(true);
            }
            setOpenTransactionDetails(false);
            setLoadingTransactionData(true);

        }).catch(function (error) {
               console.error('Request failure: ', error);
        });
    };

    const handleCloseTransactionDetails = () => {
        setOpenTransactionDetails(false);
    };

    const handleMonthChange = e => {
        setSelectedMonth(e.target.value);
        setLoadingTransactionData(true);
    }
    const handleYearChange = e => {
        setSelectedYear(e.target.value);
        setLoadingTransactionData(true);
    }

    const handleTransactionDelete = () => {
        (async () => {
            const options = {method: "DELETE", headers: getTokenHeader()};
            const response = await fetch(serverUrl +'/api/v1/transactions/' + transactionDetailData.id, options);
            const code = await response.status;
            // Exit and require login
            if(code === 401) {
                setPasswordExpired(true);
            }
            if(code === 204) {
                // On delete success refetch transactions
                setLoadingTransactionData(true);
            } else {
                console.error("Delete transaction failed")
            }
            setOpenTransactionDetails(false);
        })();
    }

    const handleRowClick = (params) => {
        setTransactionDetailData(params.row);
        setOpenTransactionDetails(true);
    };

    // need users, then categories, then transactions
    // Transaction data async load
    useEffect(() => {
        if(!accountUsersLoading && !accountFamilyLoading && loadingTransactionData) {
            (async () => {
                const options = {method: "GET", headers: getTokenHeader()};
                const response = await fetch(serverUrl +'/api/v1/transactions/' + selectedMonth + '/' + selectedYear, options);
                // Exit and require login
                if(response.status === 401) {
                    setPasswordExpired(true);
                }
                const transactions = await response.json();
                const transactionData = formatter.formatTransactions(transactions);
                console.log(transactionData);
                setTransactionData(transactionData);
                setLoadingTransactionData(false);
            })();
        }
    }, [selectedMonth, selectedYear, accountUsers, accountFamily, loadingTransactionData]);

    // Only do this if account categories are loaded in context
    function getCategoryNameForId(categoryId) {
        if(loadingAccountCategories) {
            return "";
        } else {
            return accountCategories.find((category) => category.id === categoryId).category || "Unknown Category";
        }
    }

    // Only do this if account users & family are loaded in context
    function getNameForId(id) {
        if(!accountUsersLoading && !accountFamilyLoading) {
            if(id === user.userId) {
                // currently only supporting single user accounts
                return user.fname;
            } else {
                // must be a family member
                const member = accountFamily.find((familyMember) => familyMember.id === id);
                return member ? member.firstName: "Unknown User";
            }
        } else {
            return "";
        }
    }

    const columns = [
        {
            field: 'transactionDate',
            headerName: 'Date',
            width: 150,
            valueGetter: (value) => getLocalizedDateFromEpochSeconds(value),
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 100,
            valueGetter: (value) => '$' + value,
        },
        {
            field: 'userId',
            headerName: 'Person',
            width: 150,
            valueGetter: (value) => getNameForId(value),
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 200,
            valueGetter: (value) => getCategoryNameForId(value),
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300,
        },
    ];

    return (
      <Root className={classes.root}>
          <AppDrawer/>
          <Paper className={classes.paper}>
              <Grid container alignItems={"center"}>
                  <Grid size={{ lg: 6, md: 6, sm: 12}}>
                      <DateYearSelection month={selectedMonth} year={selectedYear} handleMonthChange={handleMonthChange} handleYearChange={handleYearChange}/>
                  </Grid>
                  <Grid item size={{ lg: 6, md: 6, sm: 12, }} className={classes.floatingAddBtn}>
                      <Box sx={{ '& > :not(style)': { marginRight: 1 } }}>
                          <Tooltip title={"Add Transaction"}>
                              <Fab color="secondary" onClick={handleOpenTransactionInputModal}  aria-label="add">
                                 <AddIcon />
                              </Fab>
                          </Tooltip>
                          <Tooltip title={"Upload Transactions"}>
                              <Fab color="primary" aria-label="add" onClick={handleOpenTransactionsUpload}>
                                  <UploadFileIcon />
                              </Fab>
                          </Tooltip>
                      </Box>
                  </Grid>
                  {/*This is a spacer*/}
                  <Grid></Grid>
              </Grid>
              <Grid container>
                  <DataGrid
                     rows={transactionData}
                     columns={columns}
                     getRowId={(row) => row.id}
                     onRowClick={handleRowClick}
                     disableSelectionOnClick
                     autoHeight
                  />
              </Grid>

          </Paper>
          {/*Transaction Details Modal*/}
          <TransactionDetails open={openTransactionDetails}
                              transactionData={transactionDetailData}
                              categories={accountCategories}
                              accountUsers={accountUsers}
                              accountFamily={accountFamily}
                              handleClose={handleCloseTransactionDetails}
                              handleTransactionDetailChanges={handleTransactionDetailChanges}
                              handleTransactionDetailSave={handleTransactionDetailSave}
                              handleTransactionDelete={handleTransactionDelete}></TransactionDetails>

          {/*Transaction Input Modal*/}
          <TransactionInput open={openTransactionInputModal} accountId={user?.accountId} serverUrl={appConfig.host} categories={accountCategories} handleClose={handleCloseTransactionInputModal} saveHandler={setLoadingTransactionData}/>

          {/*Transactions Upload Modal*/}
          <TransactionUploader open={openTransactionsUpload} accountId={user?.accountId} serverUrl={appConfig.host} categories={accountCategories} handleClose={handleCloseTransactionsUpload} />
      </Root>
  );
}