 const formatters = {
    getRandomChartColor: function ()  {
       const letters = '0123456789ABCDEF';
       let color = '#';
       for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
       }
       return color;
    },

    formatTransactions: function(rawTransactions) {
        rawTransactions.sort((a,b) => b.transactionDate - a.transactionDate);
        let formatted = rawTransactions.map(transaction => {
            return {
                postedDate: transaction.postedDate,
                transactionDate: transaction.transactionDate,
                amount: transaction.amount,
                category: transaction.category,
                description: transaction.description,
                id: transaction.id,
                userId: transaction.userId,
            };
        });
        return formatted;
    },
    formatCurrency: function(value) {
       if (typeof value !== 'number') return ''; // Handle non-numeric values
       return `$${value.toLocaleString('en-US')}`; // Add $ and format with commas
    },
    // Backing dataset for Dashboard line chart
    generateTransactionDataset: function(transactions, categoryBudgets) {
       if(transactions.length === 0 || categoryBudgets.length === 0) {
          return [];
       }

       // Create a map of categoryId to initial budget amount
       const budgetMap = categoryBudgets.reduce((map, budget) => {
          map[budget.categoryId] = budget.categoryBudget;
          return map;
       }, {});

       // Calculate the starting total budget
       const startingTotalBudget = Object.values(budgetMap).reduce((sum, budget) => sum + budget, 0);

       // Create a map to store the remaining budget per category per day
       const dailyBudget = {};

       // Sort transactions by date
       transactions.sort((a, b) => a.transactionDate - b.transactionDate);

       // Iterate over transactions and update daily budget
       transactions.forEach(transaction => {
          const utcDate = new Date(transaction.transactionDate * 1000);
          const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
          const dateKey = localDate.getTime(); // Get timestamp in milliseconds
          const categoryId = transaction.category;
          const amount = parseFloat(transaction.amount);

          if (!dailyBudget[dateKey]) { // Use timestamp as key
             const previousDateKey = Object.keys(dailyBudget)
                .sort((a, b) => b - a) // Sort numerically
                .find((d) => parseInt(d) < dateKey); // Compare timestamps
             if (previousDateKey) {
                dailyBudget[dateKey] = { ...dailyBudget[previousDateKey] };
             } else {
                dailyBudget[dateKey] = { total: startingTotalBudget };
                Object.keys(budgetMap).forEach((id) => {
                   dailyBudget[dateKey][id] = budgetMap[id];
                });
             }
          }

          if (dailyBudget[dateKey][categoryId] !== undefined) {
             dailyBudget[dateKey][categoryId] -= amount;
             dailyBudget[dateKey].total -= amount;
             if (isNaN(dailyBudget[dateKey][categoryId])) {
                dailyBudget[dateKey][categoryId] = 0;
             }
             if (isNaN(dailyBudget[dateKey].total)){
                dailyBudget[dateKey].total = 0;
             }
          }
       });

       const dataset = Object.keys(dailyBudget).map((dateKey) => {
          const entry = { date: parseInt(dateKey) }; // Store timestamp as number
          Object.assign(entry, dailyBudget[dateKey]);
          return entry;
       });

       return dataset;
    }


 }

export default formatters;